import React  from "react";
import { Image, StyleSheet, View, Text, Svg } from '@react-pdf/renderer';
import ombaLogoWhiteBg from '../../../images/ombaLogoWhiteBg.png';

function Footer(){

    // Create styles
    const styles = StyleSheet.create({
      footer: {
        height: 70,
        position: "absolute",
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "row",
        width: "100%",
        bottom: 10,
        left: 35
      },
      disclaimer: {
        fontSize: 7,
        width: 500
      },
      pageNumber: {
        display: "flex",
        marginTop: 10,
        fontSize: 8,
        width: 100,
        alignItems: "center"
      },
      logo: {
        display: "flex",
        flexGrow: 2
      },
      lineBreak: {
        marginBottom: 10
      }
    });


    return <View style={styles.footer} fixed>
                <View style={styles.disclaimer}>
                  <Text>OMBA Advisory & Investments Limited is incorporated in England and Wales, Company Number 10594806. OMBA Advisory & Investments Limited is authorised and regulated by the Financial Conduct Authority 775647.</Text>
                  <Text style={styles.lineBreak} />
                  <Text>OMBA Advisory & Investments Ltd is an authorised financial services provider (FSP No. 49101) in South Africa.</Text>
                  <Text style={styles.lineBreak} />
                  <Text>www.ombainvestments.com | www.ombafunds.com</Text>
                </View>
                <View style={styles.pageNumber}>
                    <Text render={({ pageNumber, totalPages }) => (
                        `${pageNumber - 1} / ${totalPages - 3}`
                    )} />
                </View>
                <Image style={styles.logo} src={ombaLogoWhiteBg} />
    </View>
}

export default Footer;