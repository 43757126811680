import React  from "react";
import {Image, StyleSheet, View, Text} from '@react-pdf/renderer';
import HeaderInfo from "../../../interfaces/props/reports/clientReportQuarterly/headerInfo";

function Header(props: HeaderInfo){

const {
    title,
    clientName,
    date
} = props;

    // Create styles
    const styles = StyleSheet.create({
        headerContainer: {
          flexDirection: "row",
          display: "flex",
          flexWrap: "nowrap",
          flexShrink: 0,
          justifyContent: "space-between",
          alignItems: "center",
          borderBottomWidth: 1,
          borderBottomColor: "black",
          borderBottomStyle: "solid",
          padding: 10,
          paddingBottom: 5,
          width: "100%"// Adjust padding as needed
      },
      leftHeaderSection: {
          flex: 1,
          paddingRight: 10,
      },
      rightHeaderSection: {
          alignItems: "flex-end",
          justifyContent: "center",
          flexDirection: "column"
      },
      headerText: {
        fontFamily: "Merriweather",
        color: "#801C1F",
        fontWeight: "normal",
        fontSize: 28,
        paddingBottom: 2
      },
      nameText: {
          fontSize: 14,
          fontWeight: "semibold"
      },
      date: {
        fontSize: 8,
        paddingTop: 5,
        zIndex: 1
      }
    });

    return <View style={styles.headerContainer} fixed>
              <View style={styles.leftHeaderSection}>
                  <Text style={styles.headerText}>{title}</Text>
              </View>
              <View style={styles.rightHeaderSection}>
                  <Text style={styles.nameText}>{clientName}</Text>
                  <Text style={styles.date}>{date}</Text>
              </View>
          </View>
}

export default Header;