import InstrumentViewModel from "../viewmodel/instrumentViewModel";
import * as React from "react";
import OmbaInstrumentStore from "../models/instruments/stores/ombaInstrumentStore";
import {useMsal} from "@azure/msal-react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import SignInPageController from "../controllers/signInPageController";
import ProfileViewModel from "../viewmodel/profileViewModel";
import MicrosoftGraphProfileStore from "../models/profile/stores/microsoftGraphProfileStore";
import ProtectedRoute from "../routes/protectedRoute";
import InstrumentDashboardController from "../controllers/instrumentDashboardController";
import NavigationController from "../controllers/navigationController";
import EtfPositioinsDashboardController from "../controllers/etfPositionsDashboardController";
import EtfPositionsViewModel from "../viewmodel/etfPositionsViewModel";
import OmbaEtfPositionsStore from "../models/etfPositions/stores/ombaEtfPositionsStore";
import ReportGeneratorController from "../controllers/reportGeneratorController";
import ClientReportGeneratorController from "../controllers/clientReportGeneratorController";
import ClientPositionsViewModel from "../viewmodel/clientPositionsViewModel";
import CsvFileClientPositionStore from "../models/clientPositions/stores/csvFileClientPositionStore";
import OmbaInstrumentStoreWithGeneratedEnrichment
    from "../models/instruments/stores/ombaInstrumentStoreWithGeneratedEnrichment";
import EikonReferenceDataStore from "../models/referenceData/stores/eikonReferenceDataStore";
import OmbaInstrumentStoreWithEikonEnrichment
    from "../models/instruments/stores/ombaInstrumentStoreWithEikonEnrichment";
import LocalInstrumentStore
    from "../models/instruments/stores/ombaInstrumentStoreWithEikonEnrichment";
import DriveReferenceDataStore from "../models/referenceData/stores/driveReferenceDataStore";
import OmbaInstrumentStoreWithDriveEnrichment
    from "../models/instruments/stores/ombaInstrumentStoreWithDriveEnrichment";
import OmbaPmsClientPositionStore from "../models/clientPositions/stores/ombaPmsClientPositionStore";
import ClientPositionsDashboardController from "../controllers/clientPositionsDashboardController";
import PortfolioAnalysisReportDataViewModel from "../viewmodel/portfolioAnalysisReportDataViewModel";
import ClientChartingModule from '../charting/clientChartingModule';

import LocalReferenceDataStore from "../models/referenceData/stores/localReferenceDataStore";
import LocalEtfPositionsStore from "../models/etfPositions/stores/localEtfPositionsStore";
import LocalClientPositionStore from "../models/clientPositions/stores/localClientPositionStore";
import LocalInstrumentStoreWithGeneratedEnrichment
    from "../models/instruments/stores/localInstrumentStoreWithGeneratedEnrichment";
import clientPositions from "../test/sampleData/clientPositions.json";
import instruments from "../test/sampleData/instruments.json";
import etfPositions from "../test/sampleData/etfPositions.json";

import ClientReportQuarterlyDataViewModel from "../viewmodel/clientReportQuarterlyDataViewModel";
import LusidTransactionStore from "../models/transactions/stores/lusidTransactionStore";
import TransactionViewModel from "../viewmodel/transactionViewModel";
import PortfolioOverviewViewModel from "../viewmodel/portfolioOverviewViewModel";
import LusidPortfolioOverviewStore from "../models/portfolioDetails/stores/lusidPortfolioOverviewStore";
import LusidPerformanceStore from "../models/performance/stores/lusidPerformanceStore";

function Provider(){

    const { instance, accounts } = useMsal();

    const csvFilePositionStore = new CsvFileClientPositionStore("Client Holdings File: ");
    const clientChartingModule = new ClientChartingModule();

    const ombaApiUrl = process.env.REACT_APP_BASE_OMBA_API_URI || 'https://ombawebapi.azurewebsites.net';
    const eikonApiUrl = process.env.REACT_APP_BASE_EIKON_API_URI || "";
    const ombaLusidApiUrl = process.env.FBN_API_URL || "None";
    const ombaLusidAccessToken = process.env.FBN_PAT || "None";

    const ombaPmsClientPositionStore = new OmbaPmsClientPositionStore(ombaApiUrl, accounts[0], instance);
    const ombaEtfPositionsStore = new OmbaEtfPositionsStore(ombaApiUrl, accounts[0], instance)
    const driveReferenceDataStore = new DriveReferenceDataStore(ombaApiUrl, accounts[0], instance)
    const ombaInstrumentStore = new OmbaInstrumentStoreWithDriveEnrichment(ombaApiUrl, driveReferenceDataStore, accounts[0], instance);

    const instrumentViewModel = new InstrumentViewModel(ombaInstrumentStore);
    const reportInstrumentViewModel = new InstrumentViewModel(ombaInstrumentStore);
    const etfPositionsViewModel = new EtfPositionsViewModel(ombaEtfPositionsStore);
    const profileViewModel = new ProfileViewModel(new MicrosoftGraphProfileStore("https://graph.microsoft.com/v1.0", accounts[0], instance));
    const clientPositionsViewModel = new ClientPositionsViewModel(ombaPmsClientPositionStore);

    const lusidPerformanceStore = new LusidPerformanceStore(ombaApiUrl, accounts[0], instance);
    const lusidTransactionStore = new LusidTransactionStore(ombaApiUrl, accounts[0], instance);
    const lusidPortfolioOverviewStore = new LusidPortfolioOverviewStore(ombaApiUrl, accounts[0], instance);

    const transactionViewModel = new TransactionViewModel(lusidTransactionStore);
    const portfolioOverviewViewModel = new PortfolioOverviewViewModel(lusidPortfolioOverviewStore);

    const portfolioAnalysisReportDataViewModel = new PortfolioAnalysisReportDataViewModel(
        reportInstrumentViewModel,
        clientPositionsViewModel,
        etfPositionsViewModel,
        clientChartingModule
    )
    const clientReportQuarterlyDataViewModel = new ClientReportQuarterlyDataViewModel(
        reportInstrumentViewModel,
        clientPositionsViewModel,
        etfPositionsViewModel,
        clientChartingModule,
        transactionViewModel,
        portfolioOverviewViewModel,
        lusidPerformanceStore
    );

    const navLinks = [
        {
            "linkAddress": "/",
            "label": "Home"
        },
        {
            "linkAddress": "/login",
            "label": "Login"
        },
        {
            "linkAddress": "/instruments",
            "label": "Instruments Dashboard"
        },
        {
            "linkAddress": "/etf_positions",
            "label": "ETF Positions Dashboard"
        },
        {
            "linkAddress": "/client_positions",
            "label": "Client Positions Dashboard"
        },
        {
            "linkAddress": "/reports",
            "label": "Reporting"
        },
        {
            "linkAddress": "/clientreports",
            "label": "Client Reporting"
        },
    ];

    return (
        <div>
            <NavigationController navLinks={navLinks} viewModel={profileViewModel}/>
            <BrowserRouter>
                <Switch>
                    <Route path="/login">
                        <SignInPageController/>
                    </Route>
                    <ProtectedRoute path="/instruments">
                        <InstrumentDashboardController viewModel={instrumentViewModel}/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/etf_positions">
                        <EtfPositioinsDashboardController
                            instrumentViewModel={instrumentViewModel}
                            etfPositionViewModel={etfPositionsViewModel}
                        />
                    </ProtectedRoute>
                    <ProtectedRoute path="/client_positions">
                        <ClientPositionsDashboardController
                            clientPositionViewModel={clientPositionsViewModel}
                        />
                    </ProtectedRoute>
                    <ProtectedRoute path="/reports">
                        <ReportGeneratorController
                            clientPositionsViewModel={clientPositionsViewModel}
                            etfPositionsViewModel={etfPositionsViewModel}
                            instrumentViewModel={reportInstrumentViewModel}
                            clientPositionsCsvReader={csvFilePositionStore.csvReader}
                            portfolioAnalysisReportDataViewModel={portfolioAnalysisReportDataViewModel}
                        />
                    </ProtectedRoute>
                    <ProtectedRoute path="/clientreports">
                        <ClientReportGeneratorController
                            clientPositionsCsvReader={csvFilePositionStore.csvReader}
                            clientReportQuarterlyDataViewModel={clientReportQuarterlyDataViewModel}
                        />
                    </ProtectedRoute>
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default Provider;