import {default as React, ReactElement, useEffect, useState} from 'react';
import {PDFViewer} from '@react-pdf/renderer';
import Report from "../views/adminLTE/report";
import Chart from "../interfaces/props/reports/chart";
import ClientReportQuarterly from "../reportTemplates/clientReportQuarterly";
import ClientReportQuarterlyDataCollection from "../interfaces/props/reports/clientReportQuarterlyDataCollection";
import ClientReportQuarterlyTables from "../interfaces/props/reports/clientReportQuarterlyTables";
import ClientReportQuarterlyCharts from "../interfaces/props/reports/clientReportQuarterlyCharts";
import ClientReportGeneratorControllerInfo from "../interfaces/props/controllers/clientReportGeneratorController";
import FormField from "../interfaces/formField";
import BasicFormInfo from "../interfaces/props/views/basicFormInfo";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

function ClientReportGeneratorController(props: ClientReportGeneratorControllerInfo){

    //#region Constants
    const {
        clientPositionsCsvReader,
        clientReportQuarterlyDataViewModel
    } = props;

    //#region State
    // State
    const [renderReport, setRenderReport] = useState<number>(0);
    const [displayReport, setDisplayReport] = useState<boolean>(false);

    // Selection
    const defaultFormFields: {[id: string]: FormField} = {
        // Need to work out how to make these datepickers
        // Text input field for the report date
        "reportDate": new FormField(
            "Report Date",
            "reportDate",
            "reportDate",
            "string",
            "Input",
            false,
            "",
            "2022-02-02"
        ),
        // Text input field for the latest date
        "latestDate": new FormField(
            "Latest Date",
            "latestDate",
            "latestDate",
            "string",
            "Input",
            false,
            "",
            "2022-02-02"
        ),
        // I need to work out how to make this a dropdown
        "clientCode": new FormField(
            "Client Code",
            "clientCode",
            "clientCode",
            "string",
            "Input",
            false,
            "",
            "Omba Moderate Fund"
        )
    };

    const [formFields, setFormFields] = useState<{[id: string]: FormField}>(defaultFormFields);

    const [data, setData] = useState<ClientReportQuarterlyDataCollection>({
      portfolioAnalysis: {
           reportDate: {
            clientPositions: [],
            overlap: {}
          },
          latestDate: {
            clientPositions: [],
            overlap: {}
          }
      },
      performance: [],
      details: {
        ombaBankAccountNumber: '',
        baseCurrency: '',
        accountingMethod: '',
        ombaCustodian: '',
        clientName: '',
        address: ''
      },
      summary: {
        beginningMarketValue: 0,
        depositsContributions: 0,
        withdrawals: 0,
        changeInValue: 0,
        endingBalance: 0
      },
      clientPositions: [],
      transactions: []
    });


    // Logs
    const [warnings, setWarnings] = useState<[string,string][]>([]);
    const [progress, setProgress] = useState<string[]>([]);

    //Tables, which I can probably initialise to DataTableData objects
    const [tables, setTables] = useState<ClientReportQuarterlyTables>({
        portfolioDetails: {
            columns: [],
            rows: []
        },
        exchangeRates: {
            columns: [],
            rows: []
        },
        summary: {
            columns: [],
            rows: []
        },
        assetAllocation: {
            columns: [],
            rows: []
        },
        clientPositionsBrief: {
            columns: [],
            rows: []
        },
        currencyAllocation: {
            columns: [],
            rows: []
        },
        portfolioAnalysisTables: {
            clientPositions: {
                columns: [],
                rows: []
            },
            clientPositionsBrief: {
                columns: [],
                rows: []
            },
            clientPositionsFixedIncome: {
                columns: [],
                rows: []
            },
            top10UnderlyingEquities: {
                columns: [],
                rows: []
            },
            clientPositionsFixedIncomeBrief: []
        },
        tradingTransactions: {
            columns: [],
            rows: []
        },
        cashTransactions: {
            columns: [],
            rows: []
        },
        allClientPositions: {
            columns: [],
            rows: []
        },
        performance: {
            columns: [],
            rows: []
        },
    });

    // Charts
    const defaultChart: Chart = { image: '' };

    const [charts, setCharts] = useState<ClientReportQuarterlyCharts>({
        clientPositionsAssetClassSunburst: defaultChart,
        clientPositionsEtfSunburst: defaultChart,
        performanceLineChart: defaultChart,
        clientHoldingsSunburstPlot: defaultChart,
        etfEquitySectorExposureBarChart: defaultChart,
        etfEquityIndustryExposureBarChart: defaultChart,
        etfEquitySectorIndustryExposureSunburstChart: defaultChart,
        etfEquityCompanyExposureBarChart: defaultChart,
        etfEquityHeadquartersExposureBarChart: defaultChart,
        etfEquityHeadquartersExposureByEtfBarChart: defaultChart,
        etfEquityHeadquartersExposureByPortfolioMap: defaultChart,
        etfFixedIncomeCreditRatingExposureBarChart: defaultChart,
        etfOverlapChart: defaultChart,
    });

    // Report
    const [report, setReport] = useState<ReactElement>();
    //#endregion

    //#region Functions
    const addWarningMessage = (warningLabel: string, warningMessage: string) => {
        setWarnings(currentWarnings => [...currentWarnings, [warningLabel, warningMessage]]);
    }

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {

        let formFieldId = e.currentTarget.id.split("-")[0];
        let formField = formFields[formFieldId];

        if (e.currentTarget.id.includes("-select")) {
            formField.selectValue = e.currentTarget.value;
        } else {
            formField.value = e.currentTarget.value;
        }

        setFormFields(prevState => (
            {
                ...prevState,
                [formFieldId]: formField
            }));
    };

    const onSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        setRenderReport(renderReport + 1);
        setDisplayReport(true);
    };

    const onAdd = (e: React.FormEvent<HTMLInputElement>) => {
        setDisplayReport(false);
    };
    //#endregion

    //#region UseEffects
    // Get Client Positions Data
    useEffect(() => {
        if (renderReport === 0) {
            return;
        }

        // Clear warnings
        setWarnings([]);

        // Reset progress and add initial messages
        setProgress(["Loading Client Report Data..."]);
        setProgress((prevProgress) => [
            ...prevProgress,
            "Let's get started!",
            `Supplying ${formFields["reportDate"].value} and ${formFields["latestDate"].value} for client ${formFields["clientCode"].value}`,
        ]);

        // Fetch client report data
        clientReportQuarterlyDataViewModel
            .getClientReportQuarterlyData(
                setProgress, // Pass setProgress for logging
                formFields["reportDate"].value,
                formFields["latestDate"].value,
                formFields["clientCode"].value
            )
            .then((data) => setData(data))
            .catch((error) =>
                MySwal.fire(
                    `There was an error fetching data: ${error}. Please review and try again.`
                )
            );
    }, [renderReport]);



    // Create tables
    useEffect(() => {

        if (renderReport == 0){
            return;
        }

        const reportDate = formFields["reportDate"].value ?? '';
        const latestDate = formFields["latestDate"].value ?? '';

        setProgress(currentProgress => [...currentProgress, "Generating Tables..."]);
        clientReportQuarterlyDataViewModel.getClientReportQuarterlyTables(data, reportDate, latestDate, data.details.baseCurrency)
        .then((tables) => setTables(tables))

    }, [data]);

    // Create charts
    useEffect(() => {

        if (renderReport == 0){
            return;
        }

        setProgress(currentProgress => [...currentProgress, "Generating Charts..."]);

        clientReportQuarterlyDataViewModel.getClientReportQuarterlyCharts(data).then((charts) => setCharts(charts));

    }, [tables]);

    // Create report
    useEffect(() => {

        if (renderReport == 0){
            return;
        }

        setProgress(currentProgress => [...currentProgress, "Generating Report..."]);

        const reportStartDate = new Date(formFields["reportDate"].value);
        const reportEndDate = new Date(formFields["latestDate"].value);
        const options: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        };
        setReport(<ClientReportQuarterly
            clientCode={formFields["clientCode"].value}
            clientName={data.details.clientName}
            reportStartDate={reportStartDate.toLocaleDateString('en-US', options)}
            reportEndDate={reportEndDate.toLocaleDateString('en-US', options)}
            tables={tables}
            charts={charts}
        />
        );
    }, [charts]);
    //#endregion

    //#region Components
    // Create Document Component
    const MyDocument = () => {

        if (!displayReport){
            return <div></div>
        }

        return (
            <PDFViewer width="100%" height="1500">
                {report}
            </PDFViewer>
        );
    };

    const formInfo: BasicFormInfo = {
        formSubmitAction: {
            action: onSubmit
        },
        formOnChangeAction: {
            action: onChange
        },
        formSubmitLabel: "Generate Report",
        formAddLabel: "Close Report Preview",
        formFields: Object.values(formFields).concat(
            progress.map(entry => {
                return new FormField(
                    entry,
                    entry,
                    entry,
                    "string",
                    `Progress`,
                    false,
                    "",
                    undefined,
                    undefined,
                    false,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    true,
                )
            })
        ).concat(
            warnings.map(warning => {
                return new FormField(
                    warning[1],
                    warning[1],
                    warning[1],
                    "string",
                    `Warnings: ${warning[0]}`,
                    false,
                    "",
                    undefined,
                    undefined,
                    false,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    true,
                )
            })
        ),
        formOnAddAction: {
            action: onAdd
        },
        formCloseAction: {
            action: null
        }
    };
    //#endregion

    return(
        <Report
            title="Client Reports"
            report={MyDocument()}
            csvReader={clientPositionsCsvReader}
            formInfo={formInfo}
        />
    )

}

export default ClientReportGeneratorController;