import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";
import {ombaApiScopes} from "../../../authConfig";
import PerformanceMetric from "../performanceMetric";
import PerformanceMetrics from "../performanceMetrics";
import PerformanceStore from "./performanceStore";
import LusidPerformanceMetric from "../../../interfaces/lusid/lusidPerformanceMetric";

/*
Represents a store of Performance data
 */
class LusidPerformanceStore implements PerformanceStore{

    baseApiUrl: string;

    account?: AccountInfo;

    instance?: IPublicClientApplication;

    private accessToken?: string;

    constructor(baseApiUrl: string, account?: AccountInfo, instance?: IPublicClientApplication, accessToken?: string, useLUSID?: boolean){
        this.baseApiUrl = baseApiUrl;
        this.account = account;
        this.instance = instance;
        this.accessToken = accessToken;
    }

    setAccessToken(accessToken: string): void{
        this.accessToken = accessToken;
    }

    getAccessToken(): Promise<string>{

        if (this.accessToken != undefined){
            return new Promise((resolve, reject) => resolve(this.accessToken!))
        };

        return this.instance!.acquireTokenSilent({
            ...ombaApiScopes,
            account: this.account
        }).then((response) => {
            return response.accessToken;
        });
    }

    private pollStatus(executionId: string): Promise<Response> {
        
        return fetch(`${this.baseApiUrl}/honeycomb/api/SqlBackground/${executionId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.accessToken}`
                    },
                    method: 'GET'
                }
            )
            .then(response => response.json())
            .then((pollResponse) => {
                console.log('checkingStatus')
                if (pollResponse['status'] == 'Faulted') {
                    console.log('Faulted')
                    throw pollResponse['progress'];
                } else if (pollResponse['status'] == 'WaitingForActivation') {
                    console.log('WaitingForActivation')
                    return new Promise((resolve, reject) => {
                        setTimeout(() => resolve(this.pollStatus(executionId)), 5000);
                      });
                }
                console.log('Complete')
                return fetch(`${this.baseApiUrl}/honeycomb/api/SqlBackground/${executionId}/json`,
                    {
                        headers: {
                            'Authorization': `Bearer ${this.accessToken}`
                        },
                        method: 'GET'
                    }
                );
            });
    };
    
    getStrategyPerformance(reportDate: string): Promise<{[fundCode: string]: PerformanceMetrics}>{
        
        return fetch(`${this.baseApiUrl}/honeycomb/api/SqlBackground`,
            {
                headers: {
                    'Authorization': `Bearer ${this.accessToken}`
                },
                body: `SELECT * FROM Omba.Reporting.FundStrategyPerformance WHERE ReportDate='${reportDate}';`,
                method: 'PUT'
            })
            .then(response => response.json())
            .then(res => {
                let executionId = res['executionId'];
                console.log(executionId)
                return this.pollStatus(executionId)
            })
            .then(res => res.json())
            .then(res => {

                let fundPerformanceMetrics: {[fundCode: string]: PerformanceMetric[]} = {};

                res.forEach((performanceMetric: any) => {
                    if (!(performanceMetric['PortfolioCode'] in fundPerformanceMetrics)){
                        fundPerformanceMetrics[performanceMetric['PortfolioCode']] = []
                    }
                    fundPerformanceMetrics[performanceMetric['PortfolioCode']].push(
                        new PerformanceMetric(
                            performanceMetric['PortfolioScope'],
                            performanceMetric['PortfolioCode'],
                            performanceMetric['EffectiveAt'],
                            performanceMetric['FromEffectiveAt'],
                            performanceMetric['ToEffectiveAt'],
                            performanceMetric['BenchmarkCode'],
                            performanceMetric['1D'],
                            performanceMetric['WTD'],
                            performanceMetric['MTD'],
                            performanceMetric['YTD'],
                            performanceMetric['1Y'],
                            performanceMetric['2Y'],
                            performanceMetric['3Y'],
                            performanceMetric['Inception'],
                            performanceMetric['Inception Annualised'],
                            performanceMetric['Inception Annualised Volatility'],
                            performanceMetric['Error'],
                            performanceMetric['Benchmark_1D'],
                            performanceMetric['Benchmark_WTD'],
                            performanceMetric['Benchmark_MTD'],
                            performanceMetric['Benchmark_YTD'],
                            performanceMetric['Benchmark_1Y'],
                            performanceMetric['Benchmark_2Y'],
                            performanceMetric['Benchmark_3Y'],
                            performanceMetric['Benchmark_Inception'],
                            performanceMetric['Benchmark_Inception Annualised'],
                            performanceMetric['Benchmark_Inception Annualised Volatility'],
                            performanceMetric['Benchmark_Error'],
                        )
                    )
                })

                let performanceMetrics: {[fundCode: string]: PerformanceMetrics} ={};

                for (const [fundCode, performanceMetricList] of Object.entries(fundPerformanceMetrics)){
                    performanceMetrics[fundCode] = new PerformanceMetrics(performanceMetricList)
                }

                return performanceMetrics;
            });
    }

    getFundPerformance(reportDate: string): Promise<{[fundCode: string]: PerformanceMetrics}>{
        
        return fetch(`${this.baseApiUrl}/honeycomb/api/SqlBackground`,
            {
                headers: {
                    'Authorization': `Bearer ${this.accessToken}`
                },
                body: `SELECT * FROM Omba.Reporting.FundPerformance WHERE ReportDate='${reportDate}';`,
                method: 'PUT'
            })
            .then(response => response.json())
            .then(res => {
                let executionId = res['executionId'];
                console.log(executionId)
                return this.pollStatus(executionId)
            })
            .then(res => res.json())
            .then(res => {

                let fundPerformanceMetrics: {[fundCode: string]: PerformanceMetric[]} = {};

                res.forEach((performanceMetric: any) => {
                    if (!(performanceMetric['PortfolioCode'] in fundPerformanceMetrics)){
                        fundPerformanceMetrics[performanceMetric['PortfolioCode']] = []
                    }
                    fundPerformanceMetrics[performanceMetric['PortfolioCode']].push(
                        new PerformanceMetric(
                            performanceMetric['PortfolioScope'],
                            performanceMetric['PortfolioCode'],
                            performanceMetric['EffectiveAt'],
                            performanceMetric['FromEffectiveAt'],
                            performanceMetric['ToEffectiveAt'],
                            performanceMetric['BenchmarkCode'],
                            performanceMetric['1D'],
                            performanceMetric['WTD'],
                            performanceMetric['MTD'],
                            performanceMetric['YTD'],
                            performanceMetric['1Y'],
                            performanceMetric['2Y'],
                            performanceMetric['3Y'],
                            performanceMetric['Inception'],
                            performanceMetric['Inception Annualised'],
                            performanceMetric['Inception Annualised Volatility'],
                            performanceMetric['Error'],
                            performanceMetric['Benchmark_1D'],
                            performanceMetric['Benchmark_WTD'],
                            performanceMetric['Benchmark_MTD'],
                            performanceMetric['Benchmark_YTD'],
                            performanceMetric['Benchmark_1Y'],
                            performanceMetric['Benchmark_2Y'],
                            performanceMetric['Benchmark_3Y'],
                            performanceMetric['Benchmark_Inception'],
                            performanceMetric['Benchmark_Inception Annualised'],
                            performanceMetric['Benchmark_Inception Annualised Volatility'],
                            performanceMetric['Benchmark_Error'],
                        )
                    )
                })

                let performanceMetrics: {[fundCode: string]: PerformanceMetrics} ={};

                for (const [fundCode, performanceMetricList] of Object.entries(fundPerformanceMetrics)){
                    performanceMetrics[fundCode] = new PerformanceMetrics(performanceMetricList)
                }

                return performanceMetrics;
            });
    }

    getClientPerformance(accountNumber: string, reportStartDate: string, reportEndDate: string): Promise<PerformanceMetric[]> {
        return this.getAccessToken().then((accessToken) => {
            return fetch(`${this.baseApiUrl}/performance/client/?clientCode=${accountNumber}&reportStartDate=2017-01-01&reportEndDate=${reportEndDate}`,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                    method: 'GET'
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('API call failed. Please ensure that API tests succeed before using UI.');
                    }
                    return response.json();
                })
                .then((lusidPerformanceMetrics: LusidPerformanceMetric[]) => {
                    return lusidPerformanceMetrics.map(lusidPerformanceMetric => {
                        return new PerformanceMetric('',
                            lusidPerformanceMetric.PortfolioCode || '',
                            lusidPerformanceMetric.EffectiveAt || '',
                            lusidPerformanceMetric.EffectiveAt || '',
                            lusidPerformanceMetric.EffectiveAt || '',
                            lusidPerformanceMetric.BenchmarkCode || '',
                            lusidPerformanceMetric.Portfolio_1D || 0,
                            lusidPerformanceMetric.Portfolio_WTD || 0,
                            0,
                            lusidPerformanceMetric.Portfolio_YTD || 0,
                            lusidPerformanceMetric.Portfolio_1Y || 0,
                            lusidPerformanceMetric.Portfolio_2Y || 0,
                            lusidPerformanceMetric.Portfolio_3Y || 0,
                            lusidPerformanceMetric.Portfolio_Inception || 0,
                            lusidPerformanceMetric.Portfolio_Inception_Annualised || 0,
                            lusidPerformanceMetric.Portfolio_Inception_Annualised_Volatility || 0,
                            '',
                            lusidPerformanceMetric.Benchmark_1D || 0,
                            lusidPerformanceMetric.Benchmark_WTD || 0,
                            0,
                            lusidPerformanceMetric.Benchmark_YTD || 0,
                            lusidPerformanceMetric.Benchmark_1Y || 0,
                            lusidPerformanceMetric.Benchmark_2Y || 0,
                            lusidPerformanceMetric.Benchmark_3Y || 0,
                            lusidPerformanceMetric.Benchmark_Inception || 0,
                            lusidPerformanceMetric.Benchmark_Inception_Annualised || 0,
                            lusidPerformanceMetric.Benchmark_Inception_Annualised_Volatility || 0,
                            '',
                            lusidPerformanceMetric.Portfolio_1Y_Annualised || 0,
                            lusidPerformanceMetric.Portfolio_2Y_Annualised || 0,
                            lusidPerformanceMetric.Portfolio_3Y_Annualised || 0,
                            lusidPerformanceMetric.Benchmark_1Y_Annualised || 0,
                            lusidPerformanceMetric.Benchmark_2Y_Annualised || 0,
                            lusidPerformanceMetric.Benchmark_3Y_Annualised || 0
                        );
                    });
                })
        })
    }

    getPerformance(reportDate: string): Promise<{ [fundCode: string]: PerformanceMetrics }> {
        throw new Error('Not Implemented');
    }
}

export default LusidPerformanceStore;