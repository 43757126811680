const formatWeight = (weight: number, includePlusIfPositive: boolean = false) : string => {
    let weightValue = `${(weight * 100).toFixed(2)}%`;

    if (!includePlusIfPositive){
        return weightValue
    }

    return (weight > 0) ? `+${weightValue}` : weightValue;
};


export default formatWeight;