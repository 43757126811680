import React, { Fragment } from "react";
import Column from "../../../interfaces/column";
import {StyleSheet, Text, View } from '@react-pdf/renderer';
import TableRowInfo from "../../../interfaces/props/reports/tableRowInfo";
import { Style } from "@react-pdf/types";
import columnWidthCalculator from "../columnWidthCalculator";

function TableRow(props: TableRowInfo){

    const columns: Column[] = props.columns;
    const row: { [source: string] : {[attribute: string] : string}} = props.row;
    const rowStyles: Style = props.rowStyles;
    const cellStyles: Style = props.cellStyles;

    const getFontWeight = (column: Column) => {
        if (column.bold){
            return 'bold'
        }
        return 'normal'
    }

    const formatValue = (value: any): string | any => {
        if (typeof value === 'number') {
            return value.toLocaleString('en-US', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3
            });
        }
        return value;
    };

    const rowData = columns.map(column => {
        if (column.field in row) {
            const value = row[column.field];
            const isNumber = typeof value === 'number';
            const formattedValue = formatValue(value);

            return (
                <Text
                    key={column.field}
                    style={[
                        cellStyles,
                        { width: columnWidthCalculator(column, props.columns) },
                        { fontWeight: getFontWeight(column) },
                        { textAlign: isNumber ? 'right' : 'left' },
                        (props.isTotal && value !== null) ? {
                            borderTopWidth: 0.7,
                            borderTopColor: "black",
                            borderTopStyle: "solid",
                            borderBottomWidth: 0.7,
                            borderBottomColor: "black",
                            borderBottomStyle: "solid",
                        } : {}
                    ]}
                >
                    {formattedValue}
                </Text>
            );
        } else {
            return (
                <Text
                    key={column.field}
                    style={[
                        cellStyles,
                        { width: columnWidthCalculator(column, props.columns) }
                    ]}
                >
                </Text>
            );
        }
    });



    return <View wrap={false} style={[rowStyles]}>{rowData}</View>
}

export default TableRow