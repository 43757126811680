class Transaction {
  accountNumber: string;
  portfolioCode: string;
  description: string;
  isin?: string;
  name?: string;
  currency: string;
  transactionDate: string;
  settlementDate: string;
  ombaTransactionType: string;
  type: string;
  units: number;
  price: number;
  settlementAmount: number;
  exchangeRate?: number | string;
  settlementAmountBase?: number | string;

  constructor(
    accountNumber: string,
    portfolioCode: string,
    description: string,
    currency: string,
    transactionDate: string,
    settlementDate: string,
    ombaTransactionType: string,
    type: string,
    units: number,
    price: number,
    settlementAmount: number,
    isin?: string,
    name?: string,
    exchangeRate?: number | string,
    settlementAmountBase?: number | string
  ) {
    this.accountNumber = accountNumber;
    this.portfolioCode = portfolioCode;
    this.description = description;
    this.currency = currency;
    this.transactionDate = transactionDate;
    this.settlementDate = settlementDate;
    this.ombaTransactionType = ombaTransactionType;
    this.type = type;
    this.units = units;
    this.price = price;
    this.settlementAmount = settlementAmount;
    this.isin = isin;
    this.name = name;
    this.exchangeRate = exchangeRate;
    this.settlementAmountBase = settlementAmountBase;
  }
}

export default Transaction;
