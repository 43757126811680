const prettify = (value: string, maximumFractionDigits: number = 2, minimumFractionDigits: number = 0) : string => {

    // We check both parseFloat and Number to ensure values such as '2022 This is not really a number' don't become numbers
    if (!isNaN(parseFloat(value)) && !isNaN(Number(value))){

        let number = parseFloat(value);

        if (number > 1000000000){
            return `${(parseFloat(value) / 1000000000).toLocaleString('en-GB', {
                "maximumFractionDigits": 2,
            })} bn`
        }

        if (number > 1000000){
            return parseFloat(value).toLocaleString('en-GB', {
                "maximumFractionDigits": 0,
            })
        }

        return parseFloat(value).toLocaleString('en-GB', {
            "maximumFractionDigits": maximumFractionDigits,
            "minimumFractionDigits": minimumFractionDigits
        })
    }

    return value;
};

export default prettify;