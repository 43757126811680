import { Font } from "@react-pdf/renderer";
import MerriweatherRegular from "../fonts/Merriweather/Merriweather-Regular.ttf"
import MerriweatherLightItalic from "../fonts/Merriweather/Merriweather-LightItalic.ttf"
import MerriweatherBold from "../fonts/Merriweather/Merriweather-Bold.ttf"
import MerriweatherLight from "../fonts/Merriweather/Merriweather-Light.ttf"
import GothicA1Light from "../fonts/GothicA1/GothicA1-Light.ttf"
import GothicA1Medium from "../fonts/GothicA1/GothicA1-Medium.ttf"
import GothicA1SemiBold from "../fonts/GothicA1/GothicA1-SemiBold.ttf"
import GothicA1Bold from "../fonts/GothicA1/GothicA1-Bold.ttf"
import Arial from "../fonts/Arial/arial.ttf"
import ArialBold from "../fonts/Arial/arialb.ttf"

export default function RegisterFonts(){

    Font.registerHyphenationCallback(word => (
        [word]
    ));

    Font.register({
        family: 'Merriweather',
        fonts: [
            { src: MerriweatherRegular }, // font-style: normal, font-weight: normal
            { src: MerriweatherLight, fontWeight: "light"},
            { src: MerriweatherBold, fontWeight: "semibold"},
            { src: MerriweatherLightItalic, fontWeight: "light", fontStyle: "italic"},
        ]});

    Font.register({
        family: 'GothicA1',
        fonts: [
            { src: GothicA1Light, fontWeight: "light"},
            { src: GothicA1Medium, fontWeight: "medium"},
            { src: GothicA1SemiBold, fontWeight: "semibold"},
            { src: GothicA1Bold, fontWeight: "bold"},
        ]});

    Font.register({
        family: 'Arial',
        fonts: [
            { src: Arial }, // font-style: normal, font-weight: normal
            { src: ArialBold, fontWeight: "bold" },
        ]});
}