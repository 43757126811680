import React from 'react';
import SmallBox from "./displays/smallBox";
import BasicTable from "../dataTables/basicTable";
import ContentInfo from "../../interfaces/props/views/contentInfo";
import SmallBoxInfo from "../../interfaces/props/views/smallBoxInfo";
import Bootstrap from "../../constants/bootstrap";
import BasicFormModal from "../modals/basicFormModal";
import ClickAction from "../../interfaces/clickAction";

function Content(props: ContentInfo){

    const {
        title,
        clickLabel,
        newEntityFormModalInfo,
        editEntityFormModalInfo,
        datatableInfo,
        displayBoxes,
        exportFunction,
        searchFunction
    } = props;


    /**
     * Creates the HTML to display a collection of small display boxes. These can be used to display simple top line
     * metrics.
     *
     * @param smallDisplayBoxes The details for the small boxes to display
     */
    function renderDisplayBoxes(smallDisplayBoxes: SmallBoxInfo[]){
        /*
        Bootstrap uses a grid system (https://getbootstrap.com/docs/4.0/layout/grid/) if we want to display
        a variable number of components in a row we need to determine the width of each component, for example there
        are currently 12 columns available inside each row, so if we have 4 components to display in the row we want
        each to have a width of 12/4 = 3 columns. The width is controlled by the number at the end of the class name,
        for example in 'col-lg-3' will use 3 columns, 'col-log-4' will use 4 columns etc.
        */
        let displayBoxWidth = Math.floor(Bootstrap.NumberOfColumns / smallDisplayBoxes.length);

        return smallDisplayBoxes.map(smallDisplayBox => {
            return <div className={"col-lg-" + displayBoxWidth}>
                {/* small box */}
                <SmallBox type={smallDisplayBox.type} value={smallDisplayBox.value} label={smallDisplayBox.label}/>
            </div>
        })
    }

    function renderSearchExportButtons(exportFunction: ClickAction, searchFunction?: ClickAction){
        if (searchFunction == undefined){
            return <div className="row">
                <div className="col-lg-1 offset-11">
                    <button
                        type="button"
                        className="btn btn-block btn-primary"
                        onClick={exportFunction.action}>Export
                    </button>
                </div>
            </div>
        }

        return <div className="row">
                <div className="col-lg-1">
                    <button
                        type="button"
                        className="btn btn-block btn-primary"
                        onClick={searchFunction.action}>Search
                    </button>
                </div>
                <div className="col-lg-1 offset-10">
                    <button
                        type="button"
                        className="btn btn-block btn-primary"
                        onClick={exportFunction.action}>Export
                    </button>
                </div>
            </div>
    }

    /* The HTML below is from the adminLTE template, some components such as the display boxes have been pulled out into
    their own components to make modifying the view easier.

    Note that the content below consists of four rows.

    1) A Header row
    2) A row containing display boxes
    3) A row containing a datatable
    4) A row containing charts

    Inside each row there can be multiple components each taking up one or more columns.
    */
    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Dashboard</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">{title}</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}
                        <div className="row">
                            {renderDisplayBoxes(displayBoxes)}
                            {/* ./col */}
                        </div>

                        {/* Datatable */}
                        <div className="row">
                            <div className="col-lg-2 col-1">
                                <button
                                    type="button"
                                    className="btn btn-block btn-primary"
                                    data-toggle="modal"
                                    data-target={"#" + newEntityFormModalInfo.modalId}>{clickLabel}
                                </button>
                                <BasicFormModal {...newEntityFormModalInfo}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <BasicTable {...datatableInfo}/>
                                <BasicFormModal {...editEntityFormModalInfo}/>
                            </div>
                        </div>
                            {renderSearchExportButtons(exportFunction, searchFunction)}
                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
        </div>
    )
}

export default Content;