import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";
import {ombaApiScopes} from "../../../authConfig";
import PortfolioOverviewStore from "./portfolioOverviewStore";
import PortfolioDetails from "../portfolioDetails";
import PortfolioSummary from "../portfolioSummary";
import LusidPortfolioDetails from "../../../interfaces/lusid/lusidPortfolioDetails";
import LusidPortfolioSummary from "../../../interfaces/lusid/lusidPortfolioSummary";

class LusidPortfolioOverviewStore implements PortfolioOverviewStore{

    baseApiUrl: string;

    account?: AccountInfo;

    instance?: IPublicClientApplication;

    private accessToken?: string;

    constructor(baseApiUrl: string, account?: AccountInfo, instance?: IPublicClientApplication, accessToken?: string, useLUSID?: boolean){
        this.baseApiUrl = baseApiUrl;
        this.account = account;
        this.instance = instance;
        this.accessToken = accessToken;
    }

    setAccessToken(accessToken: string): void{
        this.accessToken = accessToken;
    }

    getAccessToken(): Promise<string>{

        if (this.accessToken != undefined){
            return new Promise((resolve, reject) => resolve(this.accessToken!))
        };

        return this.instance!.acquireTokenSilent({
            ...ombaApiScopes,
            account: this.account
        }).then((response) => {
            return response.accessToken;
        });
    }

    getDetails(accountNumber: string): Promise<PortfolioDetails>{
        return this.getAccessToken().then((accessToken) => {
            return fetch(`${this.baseApiUrl}/static/client_data/?clientCode=${accountNumber}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
            })
            .then(response => {
                if (!response.ok) {
                    return response.text().then(text => {
                        throw new Error(`HTTP error! status: ${response.status}, body: ${text}`);
                    });
                }
                return response.json();
            })
            .then(json => {
                return json[0] as LusidPortfolioDetails;
            })
            .then(res => {
                const portfolioDetails = new PortfolioDetails(
                    res.Omba_BankAccountNumber,
                    res.BaseCurrency,
                    res.AccountingMethod,
                    res.Omba_Custodian,
                    res.ClientName,
                    res.Address
                );
                return portfolioDetails;
            });
        });
    }

    getSummary(accountNumber: string, reportStartDate: string, reportEndDate: string, reportCurrency: string): Promise<PortfolioSummary> {
        return this.getAccessToken().then((accessToken) => {
            return fetch(`${this.baseApiUrl}/account_summary/?clientCode=${accountNumber}&reportStartDate=${reportStartDate}&reportEndDate=${reportEndDate}&reportCurrency=${reportCurrency}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            })
            .then(response => {
                if (!response.ok) {
                    return response.text().then(text => {
                        throw new Error(`HTTP error! status: ${response.status}, body: ${text}`);
                    });
                }
                return response.json();
            })
            .then(json => {
                return json[0] as LusidPortfolioSummary;
            })
            .then(res => {
                const portfolioSummary = new PortfolioSummary(
                    res['BeginningMarketValue'],
                    res['DepositsContributions'],
                    res['Withdrawals'],
                    res['ChangeInValue'],
                    res['EndingBalance']
                );
                return portfolioSummary;
            });
        });
    }
}

export default LusidPortfolioOverviewStore