import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";
import {ombaApiScopes} from "../../../authConfig";
import ReferenceDataStore from "./referenceDataStore";
import InstrumentReferenceData from "../instrumentReferenceData";

class DriveReferenceDataStore implements ReferenceDataStore{

    baseApiUrl: string;
    account?: AccountInfo;
    instance?: IPublicClientApplication;
    private accessToken?: string;

    constructor(baseApiUrl: string, account?: AccountInfo, instance?: IPublicClientApplication, accessToken?: string, useLUSID?: boolean){
        this.baseApiUrl = baseApiUrl;
        this.account = account;
        this.instance = instance;
        this.accessToken = accessToken;
    }

    setAccessToken(accessToken: string): void{
        this.accessToken = accessToken;
    }

    getAccessToken(): Promise<string>{

        if (this.accessToken != undefined){
            return new Promise((resolve, reject) => resolve(this.accessToken!))
        };

        return this.instance!.acquireTokenSilent({
            ...ombaApiScopes,
            account: this.account
        }).then((response) => {
            return response.accessToken;
        });
    }

    getInstrumentReferenceData(
        instrumentIds: string[],
        referenceDataFields: any
    ): Promise<InstrumentReferenceData>{

        return this.getAccessToken().then((accessToken) => {
            return fetch(`${this.baseApiUrl}/instruments/reference_data`,
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
                method: 'GET'
            })
            .then(response => response.json())
            .then(res => {
                const referenceDataMapped: any = res.reduce((referenceDataMap: any, currentReferenceData: any) => {
                    referenceDataMap[currentReferenceData['RIC']] = currentReferenceData
                    return referenceDataMap
                }, {})
                return new InstrumentReferenceData(referenceDataMapped)
            });
        });
    }
}

export default DriveReferenceDataStore;