import PortfolioOverviewStore from "../models/portfolioDetails/stores/portfolioOverviewStore";
import PortfolioDetails from "../models/portfolioDetails/portfolioDetails";
import PortfolioSummary from "../models/portfolioDetails/portfolioSummary";

class PortfolioOverviewViewModel {

    store: PortfolioOverviewStore;

    constructor(portfolioOverviewStore: PortfolioOverviewStore) {
        this.store = portfolioOverviewStore
    }

    getDetails(accountNumber: string): Promise<PortfolioDetails>{
        return this.store.getDetails(accountNumber);
    }

    getSummary(accountNumber: string, reportStartDate: string, reportEndDate: string):  Promise<PortfolioSummary>{
        return this.store.getSummary(accountNumber, reportStartDate, reportEndDate, 'USD');
    }
}

export default PortfolioOverviewViewModel;