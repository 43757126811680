import ClientPosition from "../clientPositions/clientPosition";

class ClientPositionExtended extends ClientPosition {
  parentAccountNumber: string;
  holdingMarketValue: number;
  cost: number;
  unrealisedGain: number;
  unrealisedGainReportingCurrency: number;
  priceSource: string;
  priceEffectiveAt: string;
  portfolioPercentage: number;
  unrealisedGainPercentage: number;
  portfolioCode: string;

  constructor(
    id: number,
    rawId: number,
    custodianId: number,
    fileProcessId: number,
    assetClass: string,
    accountNumber: string,
    positionDate: string,
    currency: string,
    exchangeRate: number,
    quantity: number,
    avgorUnitCost: number,
    marketPrice: number,
    marketValue: number,
    securityName: string,
    securityIsin: string,
    securityRic: string,
    marketValueReportingCurrency: number,
    accruedInterest: number,
    parentAccountNumber: string,
    holdingMarketValue: number,
    cost: number,
    unrealisedGain: number,
    unrealisedGainReportingCurrency: number,
    priceSource: string,
    priceEffectiveAt: string,
    portfolioPercentage: number,
    unrealisedGainPercentage: number,
    assetSubClass?: string,
    securityCusip?: string,
    securitySymbol?: string,
    exchangeCode?: string,
    portfolioCode?: string
  ) {
    super(
      id,
      rawId,
      custodianId,
      fileProcessId,
      assetClass,
      accountNumber,
      positionDate,
      currency,
      exchangeRate,
      quantity,
      avgorUnitCost,
      marketPrice,
      marketValue,
      securityName,
      securityIsin,
      securityRic,
      marketValueReportingCurrency,
      accruedInterest,
      assetSubClass,
      securityCusip,
      securitySymbol,
      exchangeCode
    );

    this.parentAccountNumber = parentAccountNumber;
    this.holdingMarketValue = holdingMarketValue;
    this.cost = cost;
    this.unrealisedGain = unrealisedGain;
    this.unrealisedGainReportingCurrency = unrealisedGainReportingCurrency;
    this.priceSource = priceSource;
    this.priceEffectiveAt = priceEffectiveAt;
    this.portfolioPercentage = portfolioPercentage;
    this.unrealisedGainPercentage = unrealisedGainPercentage;
    this.portfolioCode = portfolioCode ?? '';
  }
}

export default ClientPositionExtended;