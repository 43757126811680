import {default as React, ReactElement, useEffect, useState} from 'react';
import {PDFViewer} from '@react-pdf/renderer';
import Report from "../views/adminLTE/report";
import PortfolioAnalysis from "../reportTemplates/portfolioAnalysis"
import ReportGeneratorControllerInfo from "../interfaces/props/controllers/reportGeneratorController";
import ClientPosition from "../models/clientPositions/clientPosition";
import ombaSunburst from "../charting/sunburst";
import FormField from "../interfaces/formField";
import BasicFormInfo from "../interfaces/props/views/basicFormInfo";
import Instrument from "../models/instruments/instrument";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ombaStackedBar from "../charting/stackedBar";
import {Annotations, Color, Data, PlotData} from "plotly.js";
import FullyEnrichedClientPosition from "../interfaces/fullyEnrichedClientPosition";
import InstrumentType from "../interfaces/enums/instrumentType";
import EtfPositionWithInstrument from "../interfaces/etfPositionWithInstrument";
import PortfolioAnalysisCharts from "../interfaces/props/reports/portfolioAnalysisCharts";
import EtfPosition from "../models/etfPositions/etfPosition";
import ombaMap from "../charting/map";
import chartColours from "../charting/chartColours";
import Attributes from "../interfaces/enums/attributes"
import ombaGroupedBar from "../charting/groupedBar";
import ombaHeatmap from "../charting/heatmap";
import CountryMapping from "../constants/countryMapping";
import CreditRatingMapping from "../constants/creditRatingMapping";
import PortfolioAnalysisTables from "../interfaces/props/reports/portfolioAnalysisTables";
import DataTableData from "../interfaces/datatable";
import PortfolioAnalysisReportDataCollection from "../interfaces/portfolioAnalysisReportDataCollection";
import prettify from "../utilities/prettify";
import formatDate from '../utilities/formatDate';

const MySwal = withReactContent(Swal);

function ReportGeneratorController(props: ReportGeneratorControllerInfo){

    //#region Constants
    const {
        clientPositionsViewModel,
        etfPositionsViewModel,
        instrumentViewModel,
        clientPositionsCsvReader,
        portfolioAnalysisReportDataViewModel
    } = props;

    const positionIdentifiers: string[] = [
        "Ticker",
        "ISIN",
        "Cusip",
        "RIC"
    ];
    //#endregion

    //#region State
    // State
    const [renderReport, setRenderReport] = useState<number>(0);
    const [displayReport, setDisplayReport] = useState<boolean>(false);

    // Selection
    const defaultFormFields: {[id: string]: FormField} = {
        // Text input field for the report date
        "reportDate": new FormField(
            "Report Date",
            "reportDate",
            "reportDate",
            "string",
            "Input",
            false,
            "",
            "2022-02-02"
        ),
        // Text input field for the latest date
        "latestDate": new FormField(
            "Latest Date",
            "latestDate",
            "latestDate",
            "string",
            "Input",
            false,
            "",
            "2022-02-02"
        ),
        "clientName": new FormField(
            "Client Name",
            "clientName",
            "clientName",
            "string",
            "Input",
            false,
            "",
            "Omba Moderate Fund"
        ),
        "clientAccountNumber": new FormField(
            "Client Account Number",
            "clientAccountNumber",
            "clientAccountNumber",
            "string",
            "Input",
            false,
            "",
            "OFM_USD"
        ),
        "summaryIdentifier": new FormField(
            "Summary Identifier",
            "summaryIdentifier",
            "summaryIdentifier",
            "string",
            "Input",
            true,
            "",
            "Ticker",
            positionIdentifiers,
            false,
            "summaryIdentifier-select",
            "summaryIdentifier-select",
            "Ticker",
            "Ticker"
        )
    };

    const [formFields, setFormFields] = useState<{[id: string]: FormField}>(defaultFormFields);

    // Combined source data to be used downstream
    const [clientHoldingsFullyEnriched, setClientHoldingsFullyEnriched] = useState<PortfolioAnalysisReportDataCollection>({
        reportDate: {
            clientPositions: [],
            overlap: {}
        },
        latestDate: {
            clientPositions: [],
            overlap: {}
        }
    });

    // Logs
    const [warnings, setWarnings] = useState<[string,string][]>([]);
    const [progress, setProgress] = useState<string[]>([]);

    //Tables
    const [tables, setTables] = useState<PortfolioAnalysisTables>({
        clientPositions: {
            columns: [],
            rows: []
        },
        clientPositionsBrief: {
            columns: [],
            rows: []
        },
        clientPositionsFixedIncome: {
            columns: [],
            rows: []
        },
        top10UnderlyingEquities: {
            columns: [],
            rows: []
        },
        clientPositionsFixedIncomeBrief: []
    });

    // Charts
    const [charts, setCharts] = useState<PortfolioAnalysisCharts>({});

    // Report
    const [report, setReport] = useState<ReactElement>();
    //#endregion

    //#region Functions
    const addWarningMessage = (warningLabel: string, warningMessage: string) => {
        setWarnings(currentWarnings => [...currentWarnings, [warningLabel, warningMessage]]);
    }
    portfolioAnalysisReportDataViewModel.loggingModule = addWarningMessage;

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {

        let formFieldId = e.currentTarget.id.split("-")[0];
        let formField = formFields[formFieldId];

        if (e.currentTarget.id.includes("-select")) {
            formField.selectValue = e.currentTarget.value;
        } else {
            formField.value = e.currentTarget.value;
        }

        setFormFields(prevState => (
            {
                ...prevState,
                [formFieldId]: formField
            }));
    };

    const onSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        setRenderReport(renderReport + 1);
        setDisplayReport(true);
    };

    const onAdd = (e: React.FormEvent<HTMLInputElement>) => {
        setDisplayReport(false);
    };
    //#endregion

    //#region UseEffects
    // Get Client Positions Data
    useEffect(() => {

        if (renderReport == 0){
            return;
        }

        // Clear warnings
        setWarnings([]);
        setProgress(["Loading Client Position Data..."]);

        portfolioAnalysisReportDataViewModel.getPortfolioAnalysisReportData(
            formFields["reportDate"].value,
            formFields["latestDate"].value,
            [formFields["clientAccountNumber"].value]
        ).then((clientHoldingsFullyEnriched) => setClientHoldingsFullyEnriched(clientHoldingsFullyEnriched))
        .catch(error => MySwal.fire(`There was an error ${error} fetching data, please try again later', '', 'error`));

    }, [renderReport]);

    // Create tables
    useEffect(() => {

        if (renderReport == 0){
            return;
        }

        setProgress(currentProgress => [...currentProgress, "Generating Tables..."]);

        portfolioAnalysisReportDataViewModel.getPortfolioAnalysisReportTables(
            clientHoldingsFullyEnriched
        ).then((tables) => setTables(tables))

    }, [clientHoldingsFullyEnriched]);

    // Create charts
    useEffect(() => {

        if (renderReport == 0){
            return;
        }

        setProgress(currentProgress => [...currentProgress, "Generating Charts..."]);

        portfolioAnalysisReportDataViewModel.getPortfolioAnalysisReportCharts(clientHoldingsFullyEnriched).then((charts) => setCharts(charts));

    }, [tables]);

    // Create report
    useEffect(() => {

        if (renderReport == 0){
            return;
        }

        setProgress(currentProgress => [...currentProgress, "Generating Report..."]);

        setReport(<PortfolioAnalysis
            client={formFields["clientName"].value}
            reportDate={formatDate(new Date(Date.parse(formFields["reportDate"].value)))}
            latestDate={formatDate(new Date(Date.parse(formFields["latestDate"].value)))}
            currentDate={formatDate(new Date())}
            tables={tables}
            charts={charts}
        />
        );
    }, [charts]);
    //#endregion

    //#region Components
    // Create Document Component
    const MyDocument = () => {

        if (!displayReport){
            return <div></div>
        }

        return (
            <PDFViewer width="100%" height="1500">
                {report}
            </PDFViewer>
        );
    };

    const formInfo: BasicFormInfo = {
        formSubmitAction: {
            action: onSubmit
        },
        formOnChangeAction: {
            action: onChange
        },
        formSubmitLabel: "Generate Report",
        formAddLabel: "Close Report Preview",
        formFields: Object.values(formFields).concat(
            progress.map(entry => {
                return new FormField(
                    entry,
                    entry,
                    entry,
                    "string",
                    `Progress`,
                    false,
                    "",
                    undefined,
                    undefined,
                    false,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    true,
                )
            })
        ).concat(
            warnings.map(warning => {
                return new FormField(
                    warning[1],
                    warning[1],
                    warning[1],
                    "string",
                    `Warnings: ${warning[0]}`,
                    false,
                    "",
                    undefined,
                    undefined,
                    false,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    true,
                )
            })
        ),
        formOnAddAction: {
            action: onAdd
        },
        formCloseAction: {
            action: null
        }
    };
    //#endregion

    return(
        <Report
            title={"Portfolio Analysis"}
            report={MyDocument()}
            csvReader={clientPositionsCsvReader}
            formInfo={formInfo}
        />
    )

}

export default ReportGeneratorController;