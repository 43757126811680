import React, { Fragment } from "react";
import Column from "../../interfaces/column";
import {StyleSheet, Text, View } from '@react-pdf/renderer';
import TableRowInfo from "../../interfaces/props/reports/tableRowInfo";
import { Style } from "@react-pdf/types";
import columnWidthCalculator from "./columnWidthCalculator";

function TableRow(props: TableRowInfo){
    const columns: Column[] = props.columns;
    const row: { [source: string] : {[attribute: string] : string}} = props.row;
    const rowStyles: Style = props.rowStyles;
    const cellStyles: Style = props.cellStyles;

    // Create styles
    const styles = StyleSheet.create({
        row: {
            backgroundColor: '#FFFFFF',
        },
        rowEntry: {
            color: '#3C3C3B',
            fontSize: 6,
            paddingTop: 4,
            fontFamily: "Arial"
        }}
    );


    const rowData = columns.map(column => {

        if (column.field in row){
            return <Text key={column.field} style={[cellStyles, styles.rowEntry, {width: columnWidthCalculator(column, props.columns)}]}>
                    {row[column.field]}
                </Text>
        } else {
            return <Text key={column.field} style={[cellStyles, styles.rowEntry, {width: columnWidthCalculator(column, props.columns)}]}>
                </Text>
        }
    });

    return <View wrap={false} style={[rowStyles, styles.row]}>{rowData}</View>
}

export default TableRow