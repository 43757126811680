import {useEffect, useState} from 'react';
import Dashboard from "../views/dashboard";
import Instrument from "../models/instruments/instrument";
import Column from "../interfaces/column";
import SmallBoxInfo from "../interfaces/props/views/smallBoxInfo";
import DataTableInfo from "../interfaces/props/views/dataTableInfo";
import Variant from "../interfaces/enums/variant";
import InstrumentType from "../interfaces/enums/instrumentType";
import BasicFormInfo from "../interfaces/props/views/basicFormInfo";
import FormField from "../interfaces/formField";
import FormModalInfo from "../interfaces/props/views/formModalInfo";
import Identifier from "../models/instruments/identifier";
import * as React from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ExportToCsv } from 'export-to-csv';
import InstrumentDashboardControllerInfo from "../interfaces/props/controllers/dashboardControllerInfo";
import Attribute from "../models/instruments/attribute";
import SummaryStatistic from "../models/instruments/summaryStatistic";
import DataTableData from "../interfaces/datatable";

const MySwal = withReactContent(Swal);

function InstrumentDashboardController(props:InstrumentDashboardControllerInfo) {


    const [newInstrument, setNewInstrument] = useState(false);
    const [instruments, setInstruments] = useState<Instrument[]>([]);
    const [identifiers, setIdentifiers] = useState<Identifier[]>([]);
    const [attributeSources, setAttributeSources] = useState<string[]>(["Refinitiv", "Manual"]);
    const [attributes, setAttributes] = useState<Attribute[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [pageLimit, setPageLimit] = useState<number>(100);
    const [instrumentSummary, setInstrumentSummary] = useState<SummaryStatistic[]>([]);

    /*
    This allows us to consistently reference attributes in places like tables and forms
     */
    const createAttributeKey = (source: string, name: string) : string =>  {
        return `Attribute:${source}/${name}`
    };

    /*
    This function converts all null and undefined values into an empty string "". An example of where this might
    be used is during a CSV export to prevent lots of cells having null or undefined in them.
     */
    const setMissingValuesToEmptyString = (rows: { [name: string]: any }[]) : { [name: string]: any }[] => {
        return rows.map(row => {

            let emptyValues = Object.entries(row).map(([key, value]) => {
                if (value === null){
                    value = ""
                }

                if (value === undefined){
                    value = ""
                }

                return [key, value]
            });

            return Object.fromEntries(emptyValues);
        })
    };

    // The field to use to create/update instruments
    const defaultInstrumentFormFields: { [id: string]: FormField } = {

        // Text input field for the instrument name
        "instrumentName": new FormField(
            "Instrument Name",
            "instrumentName",
            "instrumentName",
            "string",
            "Default",
            false,
            "",
            "Apple"
        ),

        // Selectable drop down for the instrument type
        "instrumentType": new FormField(
            "Instrument Type",
            "instrumentType",
            "instrumentType",
            "string",
            "Default",
            true,
            InstrumentType.Equity,
            undefined,
            Object.values(InstrumentType),
            false,
            "instrumentType-select",
            "instrumentType-select",
            InstrumentType.Equity,
            InstrumentType.Equity,
        ),

        "instrumentDescription": new FormField(
            "Instrument Description",
            "instrumentDescription",
            "instrumentDescription",
            "string",
            "Default",
            false,
            "",
            "Apple"
        ),

        "instrumentCurrency": new FormField(
            "Instrument Currency",
            "instrumentCurrency",
            "instrumentCurrency",
            "string",
            "Default",
            false,
            "",
            "USD"
        ),

        "instrumentStatus": new FormField(
            "Instrument Status",
            "instrumentStatus",
            "instrumentStatus",
            "string",
            "Default",
            false,
            "",
            "Universe"
        )
    };

    const [instrumentFormFields, setInstrumentFormFields] = useState<{ [id: string]: FormField }>(defaultInstrumentFormFields);

    // Runs every time any of the dependencies (deps) change, for example if it is determined that a new instrument
    // has been created then the instruments and identifiers are listed again
    useEffect(() => {
        setInstruments([]);
        props.viewModel.getSummary('Type').then(summaryStatistics => {
            setInstrumentSummary(summaryStatistics);
        })
        props.viewModel.listInstruments(pageLimit, 0, attributeSources.map(source => `${source}/*`), searchTerm).then(instruments => {
            setNewInstrument(false);
            setInstruments(currentInstruments => [...instruments]);
        });
        props.viewModel.listIdentifiers().then(ids => setIdentifiers(ids));

        Promise.all(attributeSources.map(attributeSource => props.viewModel.getAttributes(attributeSource))).then((values) => {
            setAttributes(values.flat())
        })
    }, [newInstrument, props.viewModel]);

    // These are the columns known in advance to use in the datatable to show instruments
    const columns: Column[] = [
        {
            label: 'Omba Instrument Id',
            field: 'ombaId',
            width: 10
        },
        {
            label: 'Instrument Type',
            field: 'type',
            width: 10
        },
        {
            label: 'Instrument Name',
            field: 'name',
            width: 10
        },
        {
            label: 'Instrument Currency',
            field: 'currency',
            width: 10
        },
        {
            label: 'Instrument Status',
            field: 'status',
            width: 10
        },
        {
            label: 'Instrument Description',
            field: 'description',
            width: 10
        }
    ];

    // These are arbitrary columns to use depending on what identifiers exist, for example if ISIN, CUSIP and RIC
    // are the available identifiers there will be an ISIN, CUSIP and RIC column
    const idColumns: Column[] = identifiers.map(identifier => {
            return {
                label: identifier.name,
                field: identifier.name,
                width: 10
            }
        }
    );

    // These are arbitrary columns to use depending on what attributes exist
    const attributeColumns: Column[] = attributes.map(attribute => {
            return {
                label: createAttributeKey(attribute.source, attribute.name),
                field: createAttributeKey(attribute.source, attribute.name),
                width: 10
            }
        }
    );

    // Handles the deletion of an instrument
    const deleteInstrument = (instrumentId: string) => {

        MySwal.fire({
            title: `Are you sure you want to delete the instrument with OmbaInstrumentId of ${instrumentId}?`,
            showCancelButton: true,
            confirmButtonText: `Delete`,
        }).then((result) => {
            if (result.isConfirmed) {
                MySwal.fire({
                    title: 'Deleting instrument... please wait',
                    icon: "info",
                    position: 'top-end',
                    showConfirmButton: false,
                    toast: true,
                    didOpen: () => {
                        Swal.showLoading();
                        props.viewModel.deleteInstrument(instrumentId).then(() => {
                            setNewInstrument(true);
                            MySwal.fire({
                                title: 'Instrument Deleted!',
                                icon: "success",
                                position: 'top-end',
                                toast: true
                            });
                        }).catch((error) => MySwal.fire({
                                title: 'Failure!',
                                text: error,
                                icon: "error",
                                position: 'top-end',
                                toast: true}));
                    }});
            }
        });
    };

    // Handles the edit of an instrument
    const editInstrument = (instrumentId: string) => {

        props.viewModel.getInstrument(instrumentId, attributeSources.map(source => `${source}/*`)).then((instrument) => {

            let formFields = defaultInstrumentFormFields;

            formFields.instrumentType.value = instrument.type;
            formFields.instrumentName.value = instrument.name;
            formFields.instrumentCurrency.value = instrument.currency;
            formFields.instrumentDescription.value = instrument.description || "";
            formFields.instrumentStatus.value = instrument.status || "";

            formFields["ombaInstrumentId"] = new FormField(
                "Omba Instrument Identifier",
                "ombaInstrumentId",
                "ombaInstrumentId",
                "string",
                "Identifiers",
                false,
                instrument?.ombaInstrumentId?.toString() || "",
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                true
            );

            if (instrument.attributes != null) {
                for (let [source, attributes] of Object.entries(instrument.attributes)) {
                    for (let [attribute, value] of Object.entries(attributes)) {
                        let attributeKey = createAttributeKey(source, attribute);
                        formFields[attributeKey] = new FormField(
                            `${source}/${attribute}`,
                            attributeKey,
                            attributeKey,
                            "string",
                            `${source} Attributes`,
                            false,
                            value
                        )
                    }
                }
            }

            const availableIdentifiers = identifiers.map(identifier => identifier.name);

            if (instrument.identifiers != null) {

                for (let [identifierType, identifierValue] of Object.entries(instrument.identifiers)) {

                    let numberFields = Object.keys(formFields).filter(key => key.includes("instrumentIdentifier")).length.toString();
                    const formFieldsId: string = "instrumentIdentifier" + numberFields;
                    const formFieldsSelectId: string = formFieldsId + "-select";

                    formFields[formFieldsId] = new FormField(
                        "Instrument Identifier",
                        formFieldsId,
                        formFieldsId,
                        "string",
                        "Identifiers",
                        true,
                        identifierValue,
                        identifierValue,
                        availableIdentifiers,
                        true,
                        formFieldsSelectId,
                        formFieldsSelectId,
                        identifierType,
                        identifierType,
                        false,
                        true
                    );
                }
            }

            setInstrumentFormFields(formFields);
        })
    };

    // Instrument counts to display on the page in the display boxes
    const instrumentCount = instrumentSummary.reduce((totalInstruments, currentStatistic) => totalInstruments + currentStatistic.count, 0);
    const cashCount = instrumentSummary.filter(instrumentSummaryStatistic => instrumentSummaryStatistic.group === InstrumentType.Cash).reduce((totalInstruments, currentStatistic) => totalInstruments + currentStatistic.count, 0);
    const equityCount = instrumentSummary.filter(instrumentSummaryStatistic => instrumentSummaryStatistic.group === InstrumentType.Equity).reduce((totalInstruments, currentStatistic) => totalInstruments + currentStatistic.count, 0);
    const fixedIncomeCount = instrumentSummary.filter(instrumentSummaryStatistic => instrumentSummaryStatistic.group === InstrumentType.FixedIncome).reduce((totalInstruments, currentStatistic) => totalInstruments + currentStatistic.count, 0);
    const otherCount = instrumentSummary.filter(instrumentSummaryStatistic => instrumentSummaryStatistic.group !== InstrumentType.FixedIncome && instrumentSummaryStatistic.group !== InstrumentType.Equity && instrumentSummaryStatistic.group !== InstrumentType.Cash).reduce((totalInstruments, currentStatistic) => totalInstruments + currentStatistic.count, 0)

    const displayBoxes: SmallBoxInfo[] = [
        {
            value: instrumentCount.toString(),
            label: "Number of Instruments",
            type: instrumentCount > 0 ? Variant.Success : Variant.Danger
        },
        {
            value: cashCount.toString(),
            label: "Number of Cash Instruments",
            type: cashCount > 0 ? Variant.Info : Variant.Danger
        },
        {
            value: equityCount.toString(),
            label: "Number of Equities",
            type: equityCount > 0 ? Variant.Info : Variant.Danger
        },
        {
            value: fixedIncomeCount.toString(),
            label: "Number of Fixed Income Securities",
            type: fixedIncomeCount > 0 ? Variant.Success : Variant.Danger
        },
        {
            value: otherCount.toString(),
            label: "Number of Other Instruments",
            type: otherCount > 0 ? Variant.Info : Variant.Danger
        }

    ];

    // This is invoked when a user wants to add another identifier when creating an instrument
    const addInstrumentIdentifierToForm = (e: Event) => {

        let numberFields = Object.keys(instrumentFormFields).filter(key => key.includes("instrumentIdentifier")).length.toString();
        const formFieldsId: string = "instrumentIdentifier" + numberFields;
        const formFieldsSelectId: string = formFieldsId + "-select";
        const availableIdentifiers = identifiers.map(identifier => identifier.name);

        // Add new selectable drop down for the identifier type and a text input for the identifier value
        setInstrumentFormFields({
                ...instrumentFormFields,
                [formFieldsId]: new FormField(
                    "Instrument Identifier",
                    formFieldsId,
                    formFieldsId,
                    "string",
                    "Identifiers",
                    true,
                    "",
                    "AAPLE",
                    availableIdentifiers,
                    true,
                    formFieldsSelectId,
                    formFieldsSelectId,
                    availableIdentifiers[0],
                    availableIdentifiers[0]
                )
            },
        );
    };

    // Ensures that when the form is updated the new state of the form is saved
    const updateInstrumentFormData = (e: React.FormEvent<HTMLInputElement>) => {

        let formFieldId = e.currentTarget.id.split("-")[0];
        let formField = instrumentFormFields[formFieldId];

        console.log(formFieldId);
        console.log(formField);

        if (e.currentTarget.id.includes("-select")) {
            formField.selectValue = e.currentTarget.value;
        } else {
            formField.value = e.currentTarget.value;
        }

        if (e.currentTarget.id.includes("instrumentType")){
            formField.value = e.currentTarget.value;
        }

        setInstrumentFormFields(prevState => (
            {
                ...prevState,
                [formFieldId]: formField
        }));
    };

    const constructAttributes = (attributes: { [id: string]: FormField }, prefix: string): { [source: string]: { [attribute: string]: any } } => {

        let attributeValues: { [source: string]: { [attribute: string]: any } } = {};

        for (const [id, formField] of Object.entries(attributes)) {
            if (id.includes(prefix)) {

                let keyParts = id.replace(prefix, '').split("/");
                let source = keyParts[0];
                let attribute = keyParts[1];

                attributeValues[source] = attributeValues[source] || {};
                attributeValues[source][attribute] = formField.value
            }
        }

        return attributeValues
    };

    // Used to construct an identifier dictionary from the values entered into the create instrument form
    const constructIdentifiers = (identifiers: { [id: string]: FormField }, prefix: string): { [identifierType: string]: string } => {

        let identifierValues: { [identifierType: string]: string } = {};

        for (const [id, formField] of Object.entries(identifiers)) {
            if (id.includes(prefix)) {
                identifierValues[formField.selectValue] = formField.value;
            }
        }

        return identifierValues;

    };

    // Handles upserting of an instrument
    const upsertInstrument = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // This ensures that the form submit does not cause a HTTP request in the browser

        MySwal.fire({
            title: 'Upserting instrument... please wait',
            icon: "info",
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            didOpen: () => {
                Swal.showLoading();
                props.viewModel.upsertInstruments([
                        new Instrument(
                            instrumentFormFields["instrumentName"].value,
                            instrumentFormFields["instrumentType"].value as InstrumentType,
                            instrumentFormFields["instrumentCurrency"].value,
                            constructIdentifiers(instrumentFormFields, "instrumentIdentifier"),
                            constructAttributes(instrumentFormFields, "Attribute:"),
                            instrumentFormFields["instrumentStatus"].value,
                            instrumentFormFields["instrumentDescription"].value)
                    ]
                ).then(() => {
                    MySwal.fire({
                        title: 'Instrument Upserted!',
                        icon: "success",
                        position: 'top-end',
                        toast: true
                    });
                    setNewInstrument(true);
                    setInstrumentFormFields(defaultInstrumentFormFields);
                })
                    .catch((error: Error) => {
                        MySwal.fire(`There was an error upserting the instrument due to: ${error.message}`, '', 'error')
                    });
            }
        });
    };

    // Handles upserting of an instrument
    const closeInstrumentForm = (e: React.FormEvent<HTMLFormElement>) => {
        setInstrumentFormFields(defaultInstrumentFormFields);
    };

    const newInstrumentFormInfo: BasicFormInfo = {
        "formSubmitAction": {
            "action": upsertInstrument
        },
        "formOnChangeAction": {
            "action": updateInstrumentFormData
        },
        "formSubmitLabel": "Create Instrument",
        "formAddLabel": "Add Identifier",
        "formFields": Object.values(instrumentFormFields),
        "formOnAddAction": {
            "action": addInstrumentIdentifierToForm
        },
        "formCloseAction": {
            "action": closeInstrumentForm
        }
    };

    const newInstrumentFormModalInfo: FormModalInfo = {
        "modalId": "NewInstrumentModal",
        "modalTitle": "Create Instrument",
        "formInfo": newInstrumentFormInfo
    };

    const editInstrumentFormInfo: BasicFormInfo = {
        "formSubmitAction": {
            "action": upsertInstrument
        },
        "formOnChangeAction": {
            "action": updateInstrumentFormData
        },
        "formSubmitLabel": "Update Instrument",
        "formAddLabel": "Add Identifier",
        "formFields": Object.values(instrumentFormFields),
        "formOnAddAction": {
            "action": addInstrumentIdentifierToForm
        },
        "formCloseAction": {
            "action": closeInstrumentForm
        }
    };

    const editInstrumentFormModalInfo: FormModalInfo = {
        "modalId": "EditInstrumentModal",
        "modalTitle": "Edit Instrument",
        "formInfo": editInstrumentFormInfo
    };

    // Creates the datatable to display
    const createDatatable = (allInstruments: Instrument[]) : DataTableData => {

        return {
            columns: [
                {
                    label: 'Edit',
                    field: 'edit-button',
                    width: 10
                }
            ].concat(columns).concat(idColumns).concat(attributeColumns).concat([
                {
                    label: 'Delete',
                    field: 'delete-button',
                    width: 10
                }
            ]), // Known plus ID columns
                rows: allInstruments.map(instrument => {
            let baseProps = {
                "type": instrument.type,
                "name": instrument.name,
                "ombaId": instrument.ombaInstrumentId,
                "status": instrument.status,
                "currency": instrument.currency,
                "description": instrument.description
            };

            let ids = Object.fromEntries(identifiers.map(identifier => {
                return [identifier.name, instrument.identifiers[identifier.name]]
            }));

            let instrument_attributes = Object.fromEntries(attributes.map(attribute => {
                return [createAttributeKey(attribute.source, attribute.name), instrument?.attributes?.[attribute.source]?.[attribute.name]]
            }));

            return {
                ...baseProps,
                ...ids,
                ...instrument_attributes,
                ...{
                    "delete-button": <button type="button"
                                             className="btn btn-block btn-danger"
                                             value={instrument.ombaInstrumentId}
                                             onClick={(e) => {
                                                 deleteInstrument(e.currentTarget.value)
                                             }}>Delete
                    </button>,

                    "edit-button": <button type="button"
                                           className="btn btn-block btn-primary"
                                           value={instrument.ombaInstrumentId}
                                           data-toggle="modal"
                                           data-target={"#" + editInstrumentFormModalInfo.modalId}
                                           onClick={(e) => {
                                               editInstrument(e.currentTarget.value)
                                           }}>Edit
                    </button>
                }
            }; // Joins the base properties with the identifiers
        })
        }
    };

    const datatable = createDatatable(instruments);

    const Export = () => {

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: 'Instruments',
            useKeysAsHeaders: true,
        };

        if (datatable.rows.length == 0) {
            MySwal.fire({
                title: 'No data to export!',
                position: 'top-end',
                icon: 'error',
                toast: true
            });
            return
        }

        const limit = instrumentSummary.reduce((totalInstruments, currentStatistic) => totalInstruments + currentStatistic.count, 0);

        MySwal.fire({
            title: `Finding up to ${limit} instruments which match current search term: "` + searchTerm + '"... please wait',
            icon: "info",
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            didOpen: () => {
                Swal.showLoading();
                props.viewModel.listInstruments(
                    limit,
                    0,
                    attributeSources.map(source => `${source}/*`), searchTerm).then(
                    instruments => {
                        MySwal.fire({
                            title: instruments.length + ' Instruments found, exporting now...',
                            icon: "info",
                            position: 'top-end',
                            toast: true
                        });
                        const csvExporter = new ExportToCsv(options);
                        csvExporter.generateCsv(setMissingValuesToEmptyString(createDatatable(instruments).rows))
                        MySwal.fire({
                            title: `Export complete with ${instruments.length} instruments exported.`,
                            icon: "success",
                            position: 'top-end',
                            toast: true
                        });
                    }
                ).catch((error: Error) => {
                    MySwal.fire(`There was an error exporting instruments due to: ${error.message}`, '', 'error')
                });
            }
        })
    };

    const Search = () => {

        MySwal.fire({
            title: 'Searching for instruments with term "' + searchTerm + '"... please wait',
            icon: "info",
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            didOpen: () => {
                Swal.showLoading();
                props.viewModel.listInstruments(
                    pageLimit,
                    0,
                    attributeSources.map(source => `${source}/*`), searchTerm).then(
                    instruments => {
                        setInstruments(currentInstruments => [...instruments])
                        MySwal.fire({
                            title: instruments.length + ' Instruments found',
                            icon: "success",
                            position: 'top-end',
                            toast: true
                        });
                    }
                ).catch((error: Error) => {
                    MySwal.fire(`There was an error searching for instruments due to: ${error.message}`, '', 'error')
                });
            }
        })
    }

    const exportFunction = {"action": Export};
    const searchFunction = {"action": Search};

    const onSearchTermUpdate = (value: string) => {
        setSearchTerm(value)
    }

    const dataTableInfo: DataTableInfo = {
        "data": datatable,
        "entries": 10,
        "onSearch": onSearchTermUpdate
    }

    return (
        <Dashboard
            dashboardTitle={"Instruments"}
            clickLabel={"Create New Instrument"}
            newEntityFormModalInfo={newInstrumentFormModalInfo}
            editEntityFormModalInfo={editInstrumentFormModalInfo}
            datatableInfo={dataTableInfo}
            displayBoxes={displayBoxes}
            exportFunction={exportFunction}
            searchFunction={searchFunction}
        />
    )
}


export default InstrumentDashboardController;