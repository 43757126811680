import ClientPositionStore from "../models/clientPositions/stores/clientPositionStore";
import ClientPosition from "../models/clientPositions/clientPosition";
import ClientPositionWithLookThrough from "../models/clientPositions/clientPositionWithLookThrough";
import ClientPositionExtended from "../models/clientPositions/clientPositionExtended";

class ClientPositionsViewModel {

    store: ClientPositionStore;

    constructor(clientPositionsStore: ClientPositionStore) {
        this.store = clientPositionsStore
    }

    getClientPositions(
        representativeDate: string,
        accountNumbers: string[]
    ): Promise<ClientPosition[]>{
        return this.store.getClientPositions(representativeDate, accountNumbers);
    }

    getClientPositionsWithLookThrough(
        representativeDate: string,
        accountNumbers: string[],
        lookThroughDepth?: string,
        aggregateMethod?: string
    ): Promise<ClientPositionWithLookThrough[]>{
        return this.store.getClientPositionsWithLookThrough(representativeDate, accountNumbers, lookThroughDepth, aggregateMethod);
    }

    getClientPositionsExtended(clientCode: string, reportDate: string): Promise<ClientPositionExtended[]>{
        const positions = this.store.getClientPositionsExtended(clientCode, reportDate);
        return positions;
    }
}

export default ClientPositionsViewModel