import { promises } from "dns";
import ClientReportQuarterlyDataCollection from "../interfaces/props/reports/clientReportQuarterlyDataCollection";
import ChartingModule from "../interfaces/modules/chartingModule";
import PortfolioAnalysisReportDataCollection from "../interfaces/portfolioAnalysisReportDataCollection";
import ClientReportQuarterlyTables from "../interfaces/props/reports/clientReportQuarterlyTables";
import ClientReportQuarterlyCharts from "../interfaces/props/reports/clientReportQuarterlyCharts";
import ClientPositionsViewModel from "./clientPositionsViewModel";
import EtfPositionsViewModel from "./etfPositionsViewModel";
import InstrumentViewModel from "./instrumentViewModel";
import PortfolioAnalysisReportDataViewModel from "./portfolioAnalysisReportDataViewModel";
import TransactionViewModel from "./transactionViewModel";
import Transaction from "../models/transactions/transaction";
import PortfolioDetails from "../models/portfolioDetails/portfolioDetails";
import PortfolioSummary from "../models/portfolioDetails/portfolioSummary";
import PortfolioOverviewViewModel from "./portfolioOverviewViewModel";
import DataTableData from "../interfaces/datatable";
import ClientPositionExtended from "../models/clientPositions/clientPositionExtended";
import PerformanceStore from "../models/performance/stores/performanceStore";
import PerformanceMetric from "../models/performance/performanceMetric"
import ombaSunburst from "../charting/sunburst";
import ombaLine from "../charting/line";

class ClientReportQuarterlyDataViewModel extends PortfolioAnalysisReportDataViewModel{

    private transactionViewModel: TransactionViewModel;
    private portfolioOverviewViewModel: PortfolioOverviewViewModel;
    private performanceStore: PerformanceStore;

    constructor(
      instrumentViewModel: InstrumentViewModel,
      clientPositionsViewModel: ClientPositionsViewModel,
      etfPositionsViewModel: EtfPositionsViewModel,
      chartingModule: ChartingModule,
      transactionViewModel: TransactionViewModel,
      portfolioOverviewViewModel: PortfolioOverviewViewModel,
      performanceStore: PerformanceStore
    ) {
      super(instrumentViewModel, clientPositionsViewModel, etfPositionsViewModel, chartingModule);
      this.transactionViewModel = transactionViewModel;
      this.portfolioOverviewViewModel = portfolioOverviewViewModel;
      this.performanceStore = performanceStore;
    }

    private getTradingTransactionTable(transactions: Transaction[], reportingCurrency: string): Promise<DataTableData> {
        return new Promise(resolve => {
            resolve({
                columns: [
                    { label: "Acct No", field: "account", width: 35 },
                    { label: "Portfolio Code", field: "portfolioCode", width: 35 },
                    { label: "Name", field: "name", width: 80 },
                    { label: "ISIN", field: "isin", width: 35 },
                    { label: "Type", field: "type", width: 20 },
                    { label: "Execution Date", field: "transactionDate", width: 35 },
                    { label: "Settlement Date", field: "settlementDate", width: 35 },
                    { label: "Units", field: "units", width: 35 },
                    { label: "Price", field: "price", width: 35 },
                    { label: "Currency", field: "currency", width: 35 },
                    { label: "Settlement Amount", field: "settlementAmount", width: 35 },
                    { label: "Exchange Rate", field: "exchangeRate", width: 35 },
                    { label: `Settlement Amount (${reportingCurrency})`, field: "settlementAmountBase", width: 35 }
                ],
                rows: transactions.map(transaction => {
                    return {
                        "account": transaction.accountNumber,
                        "portfolioCode": transaction.portfolioCode,
                        "name": transaction.name,
                        "isin": transaction.isin,
                        "type": transaction.ombaTransactionType,
                        "transactionDate": transaction.transactionDate,
                        "settlementDate": transaction.settlementDate,
                        "units": transaction.units,
                        "price": transaction.price,
                        "currency": transaction.currency,
                        "settlementAmount": transaction.settlementAmount,
                        "exchangeRate": transaction.exchangeRate,
                        "settlementAmountBase": transaction.settlementAmountBase
                    };
                })
            });
        });
    }

    private getCashTransactionTable(transactions: Transaction[]): Promise<DataTableData> {
        return new Promise(resolve => {
            resolve({
                columns: [
                    { label: "Acct No", field: "account", width: 35 },
                    { label: "Portfolio Code", field: "portfolioCode", width: 35 },
                    { label: "Description", field: "description", width: 80 },
                    { label: "Type", field: "type", width: 35 },
                    { label: "Execution Date", field: "transactionDate", width: 35 },
                    { label: "Settlement Date", field: "settlementDate", width: 35 },
                    { label: "Units", field: "units", width: 35 },
                    { label: "Price", field: "price", width: 35 },
                    { label: "Currency", field: "currency", width: 35 },
                    { label: "Settlement Amount", field: "settlementAmount", width: 35 }
                ],
                rows: transactions.map(transaction => {
                    return {
                        "account": transaction.accountNumber,
                        "portfolioCode": transaction.portfolioCode,
                        "description": transaction.description,
                        "type": transaction.ombaTransactionType,
                        "transactionDate": transaction.transactionDate,
                        "settlementDate": transaction.settlementDate,
                        "units": transaction.units,
                        "price": transaction.price,
                        "currency": transaction.currency,
                        "settlementAmount": transaction.settlementAmount
                    };
                })
            });
        });
    }

    private getClientPositionTable(clientPositions: ClientPositionExtended[], reportingCurrency: string): Promise<DataTableData> {
        return new Promise(resolve => {
            resolve({
                columns: [
                    { label: "Acct No", field: "accountNumber", width: 5 },
                    { label: "Portfolio Code", field: "portfolioCode", width: 10 },
                    { label: "Sec Name (RIC)", field: "securityNameRIC", width: 20 },
                    { label: "Curr", field: "currency", width: 5 },
                    { label: "Qty", field: "quantity", width: 10 },
                    { label: "Unit Cost", field: "avgorUnitCost", width: 7 },
                    { label: "Mkt Price", field: "marketPrice", width: 7 },
                    { label: "Book Cost", field: "cost", width: 10 },
                    { label: "Mkt Value", field: "marketValue", width: 10 },
                    { label: "Unrl Gain", field: "unrealisedGain", width: 10 },
                    { label: `Mkt Val (${reportingCurrency})`, field: "marketValueReportingCurrency", width: 10 },
                    { label: `Unrl Gain (${reportingCurrency})`, field: "unrealisedGainReportingCurrency", width: 10 },
                    { label: "Port %", field: "portfolioPercentage", width: 5 }
                ],
                rows: clientPositions.map(clientPosition => ({
                    "accountNumber": clientPosition.accountNumber,
                    "portfolioCode": clientPosition.portfolioCode,
                    "assetClass": clientPosition.assetClass,
                    "assetSubClass": clientPosition.assetSubClass,
                    "securityNameRIC": `${clientPosition.securityName}${clientPosition.securityRic ? ` (${clientPosition.securityRic})` : ''}`,
                    "currency": clientPosition.currency,
                    "quantity": clientPosition.quantity,
                    "avgorUnitCost": clientPosition.avgorUnitCost,
                    "marketPrice": clientPosition.marketPrice,
                    "cost": clientPosition.cost,
                    "marketValue": clientPosition.marketValue,
                    "unrealisedGain": clientPosition.unrealisedGain,
                    "marketValueReportingCurrency": clientPosition.marketValueReportingCurrency,
                    "unrealisedGainReportingCurrency": clientPosition.unrealisedGainReportingCurrency,
                    "portfolioPercentage": clientPosition.portfolioPercentage
                }))
            });
        });
    }

   private getPortfolioDetailsTable(portfolioDetails: PortfolioDetails): Promise<DataTableData> {
        return new Promise(resolve => {
            const rows = [
                { label: "Bank Account Number", value: portfolioDetails.ombaBankAccountNumber },
                { label: "Base Currency", value: portfolioDetails.baseCurrency },
                { label: "Accounting Method", value: portfolioDetails.accountingMethod },
                { label: "Custodian", value: portfolioDetails.ombaCustodian },
                { label: "Client Name", value: portfolioDetails.clientName },
                { label: "Client Address", value: portfolioDetails.address }
            ];

            resolve({
                columns: [
                    { label: "Field", field: "label", width: 1 },
                    { label: "Value", field: "value", width: 2 }
                ],
                rows
            });
        });
    }

    private getExchangeRateTable(clientPositions: ClientPositionExtended[]): Promise<DataTableData> {
        return new Promise(resolve => {
            const currencyMap = new Map<string, number>();
            clientPositions.forEach(position => {
                if (!currencyMap.has(position.currency)) {
                    currencyMap.set(position.currency, position.exchangeRate);
                }
            });

            const rows = Array.from(currencyMap.entries()).map(([currency, exchangeRate]) => ({
                "currency": currency,
                "exchangeRate": exchangeRate
            }));

            resolve({
                columns: [
                    { label: "Currency", field: "currency", width: 20 },
                    { label: `Exchange Rate`, field: "exchangeRate", width: 30 }
                ],
                rows: rows
            });
        });
    }

    private getAssetAllocationTable(clientPositions: ClientPositionExtended[]): Promise<DataTableData> {
        return new Promise(resolve => {
            const assetAllocationMap = new Map<string, number>();

            clientPositions.forEach(position => {
                const assetSubClass = position.assetSubClass || "Unknown"; // Default to "Unknown" if assetSubClass is undefined
                const portfolioPercentage = position.portfolioPercentage;

                if (assetAllocationMap.has(assetSubClass)) {
                    assetAllocationMap.set(assetSubClass, assetAllocationMap.get(assetSubClass)! + portfolioPercentage);
                } else {
                    assetAllocationMap.set(assetSubClass, portfolioPercentage);
                }
            });

            // Convert the map to an array of rows for the table
            const rows = Array.from(assetAllocationMap.entries()).map(([assetSubClass, summedPercentage]) => ({
                "assetSubClass": assetSubClass,
                "summedPercentage": summedPercentage
            }));

            resolve({
                columns: [
                    { label: "Asset SubClass", field: "assetSubClass", width: 50 },
                    { label: "Percentage of Portfolio", field: "summedPercentage", width: 50 }
                ],
                rows: rows
            });
        });
    }

    private getClientPositionsBriefPromise(clientPositions: ClientPositionExtended[]): Promise<DataTableData> {
        return new Promise(resolve => {
            const positionsByAssetClass: { [key: string]: ClientPositionExtended[] } = clientPositions.reduce(
             (acc: { [key: string]: ClientPositionExtended[] }, pos: ClientPositionExtended) => {
                if (!acc[pos.assetClass]) {
                    acc[pos.assetClass] = [];
                }
                acc[pos.assetClass].push(pos);
                return acc
            }, {});

            const topPositions: ClientPositionExtended[] = Object.values(positionsByAssetClass).flatMap(positionAssetClasses =>
                positionAssetClasses.slice(0, 10)
            );
            resolve({
                columns: [
                    {
                        label: "RIC",
                        field: "RIC",
                        width: 25
                    },
                    {
                        label: "ETF Name",
                        field: "name",
                        width: 100
                    },
                    {
                        label: "Weight",
                        field: "weight",
                        width: 15
                    }
                ],
                rows: topPositions.map(position => ({
                    "assetClass": position.assetClass,
                    "RIC": position.securityRic,
                    "name": position.securityName,
                    "weight": position.portfolioPercentage
                }))
                .slice(0, 30) // Select the top 30 items
            })
        })
    }

    private getCurrencyAllocationTable(clientPositions: ClientPositionExtended[]): Promise<DataTableData> {
        return new Promise(resolve => {
            const currencyAllocationMap = new Map<string, number>();

            clientPositions.forEach(position => {
                const currency = position.currency; // Currency
                const portfolioPercentage = position.portfolioPercentage; // PercentageOfPortfolio

                if (currencyAllocationMap.has(currency)) {
                    currencyAllocationMap.set(currency, currencyAllocationMap.get(currency)! + portfolioPercentage);
                } else {
                    currencyAllocationMap.set(currency, portfolioPercentage);
                }
            });

            // Convert the map to an array of rows for the table
            const rows = Array.from(currencyAllocationMap.entries()).map(([currency, summedPercentage]) => ({
                "currency": currency,
                "summedPercentage": summedPercentage
            }));

            resolve({
                columns: [
                    { label: "Currency", field: "currency", width: 50 },
                    { label: "Percentage of Portfolio", field: "summedPercentage", width: 50 }
                ],
                rows: rows
            });
        });
    }

    private getPortfolioSummaryTable(summary: PortfolioSummary, reportDate: string, latestDate: string): Promise<DataTableData> {
        return new Promise(resolve => {
            const row = {
                    'beginningMarketValue' : summary.beginningMarketValue,
                    'depositsContributions' : summary.depositsContributions,
                    'withdrawals' : summary.withdrawals,
                    'changeInValue' : summary.changeInValue,
                    'endingBalance' : summary.endingBalance
                }

            resolve({
                columns: [
                    { label: `Market Value (${reportDate})`, field: "beginningMarketValue", width: 50 },
                    { label: "Deposits and Contributions", field: "depositsContributions", width: 50 },
                    { label: "Withdrawals", field: "withdrawals", width: 50 },
                    { label: "Change in Value", field: "changeInValue", width: 50 },
                    { label: `Market Value (${latestDate})`, field: "endingBalance", width: 50 },
                ],
                rows: [row]
            });
        });
    }

    private getPerformanceTable(performance: PerformanceMetric[]): Promise<DataTableData> {
        return new Promise(resolve => {
            const reportDateMetrics = performance[performance.length - 1]; // replace with actual date if available

            // Map metrics to a performance row for client and benchmark
            const clientPerformanceRow = {
                MetricName: reportDateMetrics.portfolioCode,
                Inception: reportDateMetrics.inception,
                InceptionAnnualised: reportDateMetrics.inceptionAnnualised,
                InceptionAnnualisedVolatility: reportDateMetrics.volatilityInceptionAnnualised,
                ThreeYears: reportDateMetrics.threeYears,
                TwoYears: reportDateMetrics.twoYears,
                OneYears: reportDateMetrics.oneYear,
                ThreeYearsAnnualised: reportDateMetrics.threeYearsAnnualised,
                TwoYearsAnnualised: reportDateMetrics.twoYearsAnnualised,
                OneYearAnnualised: reportDateMetrics.oneYearAnnualised
            };

            const benchmarkPerformanceRow = {
                MetricName: reportDateMetrics.benchmarkCode,
                Inception: reportDateMetrics.benchmarkInception,
                InceptionAnnualised: reportDateMetrics.benchmarkInceptionAnnualised,
                InceptionAnnualisedVolatility: reportDateMetrics.benchmarkVolatilityInceptionAnnualised,
                ThreeYears: reportDateMetrics.benchmarkThreeYears,
                TwoYears: reportDateMetrics.benchmarkTwoYears,
                OneYears: reportDateMetrics.benchmarkOneYear,
                ThreeYearsAnnualised: reportDateMetrics.benchmarkThreeYearsAnnualised,
                TwoYearsAnnualised: reportDateMetrics.benchmarkTwoYearsAnnualised,
                OneYearAnnualised: reportDateMetrics.benchmarkOneYearAnnualised
            };

            resolve({
                columns: [
                    { label: "", field: "MetricName", width: 50 },
                    { label: "Inception", field: "Inception", width: 50 },
                    { label: "Inception Annualised", field: "InceptionAnnualised", width: 50 },
                    { label: "Inception Annualised Volatility", field: "InceptionAnnualisedVolatility", width: 50 },
                    { label: "3Y", field: "ThreeYears", width: 50 },
                    { label: "2Y", field: "TwoYears", width: 50 },
                    { label: "1Y", field: "OneYears", width: 50 },
                    { label: "3Y Annualised", field: "ThreeYearsAnnualised", width: 50 },
                    { label: "2Y Annualised", field: "TwoYearsAnnualised", width: 50 },
                    { label: "1Y Annualised", field: "OneYearAnnualised", width: 50 },
                ],
                rows: [clientPerformanceRow, benchmarkPerformanceRow]
            });
        });
    }


    private delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    getClientReportQuarterlyData(
        setProgress: React.Dispatch<React.SetStateAction<string[]>>,
        reportDate: string,
        latestDate: string,
        clientCode: string
    ): Promise<ClientReportQuarterlyDataCollection> {

    const promiseWithLog = (
        promise: Promise<any>,
        name: string,
        setProgress: React.Dispatch<React.SetStateAction<string[]>>
    ): Promise<any> => {
        const startTime = Date.now();
        return promise
            .then((result) => {
                const endTime = Date.now();
                const duration = ((endTime - startTime) / 1000).toFixed(2);
                setProgress((prevProgress) => [
                    ...prevProgress,
                    `${name} completed in ${duration} seconds.`
                ]);
                return result;
            })
            .catch((error) => {
                const errorTime = Date.now();
                const duration = ((errorTime - startTime) / 1000).toFixed(2);
                setProgress((prevProgress) => [
                    ...prevProgress,
                    `${name} failed after ${duration} seconds: ${error.message}`
                ]);
                throw error;
            });
    };

    const portfolioAnalysisPromise = promiseWithLog(
        this.getPortfolioAnalysisReportData(reportDate, latestDate, [clientCode]),
        "Portfolio Analysis",
        setProgress
    );

    const performancePromise = promiseWithLog(
        this.performanceStore.getClientPerformance(clientCode, reportDate, latestDate),
        "Performance Data",
        setProgress
    );

    const detailsPromise = promiseWithLog(
        this.portfolioOverviewViewModel.getDetails(clientCode),
        "Portfolio Details",
        setProgress
    );

    const summaryPromise = promiseWithLog(
        this.portfolioOverviewViewModel.getSummary(clientCode, reportDate, latestDate),
        "Portfolio Summary",
        setProgress
    );

    const clientPositionsPromise = promiseWithLog(
        this.clientPositionsViewModel.getClientPositionsExtended(clientCode, latestDate),
        "Client Positions",
        setProgress
    );

    const transactionsPromise = promiseWithLog(
        this.transactionViewModel.getTransactions(clientCode, reportDate, latestDate),
        "Transactions",
        setProgress
    );

        return Promise.all([
            portfolioAnalysisPromise,
            performancePromise,
            detailsPromise,
            summaryPromise,
            clientPositionsPromise,
            transactionsPromise
        ]).then(([
            portfolioAnalysisCollection,performance,details,summary,clientPositions,transactions
            ]) => {
            return {
                portfolioAnalysis: portfolioAnalysisCollection,
                performance,
                details,
                summary,
                clientPositions,
                transactions
            } as ClientReportQuarterlyDataCollection;
        });
    }

    getClientReportQuarterlyTables(
        data: ClientReportQuarterlyDataCollection,
        reportDate: string,
        latestDate: string,
        reportingCurrency: string
    ): Promise<ClientReportQuarterlyTables> {

        // PortfolioDetails
        const getPortfolioDetailsTablePromise = this.getPortfolioDetailsTable(data.details);
        const getExchangeRateTablePromise = this.getExchangeRateTable(data.clientPositions);
        const getPortfolioSummaryTablePromise = this.getPortfolioSummaryTable(data.summary, reportDate, latestDate);
        // AssetAllocation
        const getAssetAllocationTablePromise = this.getAssetAllocationTable(data.clientPositions);
        const getClientPositionsBriefPromise = this.getClientPositionsBriefPromise(data.clientPositions);
        const getCurrencyAllocationTablePromise = this.getCurrencyAllocationTable(data.clientPositions);
        // Transactions
        const isTradingTransaction = (transaction: Transaction) =>
            transaction.ombaTransactionType === 'Buy' || transaction.ombaTransactionType === 'Sell';
        const getTradingTransactionsPromise = this.getTradingTransactionTable(
            data.transactions.filter(isTradingTransaction), reportingCurrency
        );
        const getCashTransactionsPromise = this.getCashTransactionTable(
            data.transactions.filter(transaction => !isTradingTransaction(transaction))
        );
        // Client Positions
        const getAllClientPositionsTablePromise = this.getClientPositionTable(data.clientPositions, reportingCurrency);
        // PortfolioAnalysis
        const getPortfolioAnalysisTablesPromise = this.getPortfolioAnalysisReportTables(data.portfolioAnalysis);
        // Performance
        const getPerformanceTablePromise = this.getPerformanceTable(data.performance);

        return Promise.all([
            getPortfolioDetailsTablePromise as any,
            getExchangeRateTablePromise as any,
            getPortfolioSummaryTablePromise as any,
            getAssetAllocationTablePromise as any,
            getClientPositionsBriefPromise as any,
            getCurrencyAllocationTablePromise as any,
            getPortfolioAnalysisTablesPromise as any,
            getTradingTransactionsPromise as any,
            getCashTransactionsPromise as any,
            getAllClientPositionsTablePromise as any,
            getPerformanceTablePromise as any
        ]).then(([
                portfolioDetails, exchangeRates, summary, assetAllocation, clientPositionsBrief, currencyAllocation, portfolioAnalysisTables, tradingTransactions, cashTransactions, allClientPositions, performance
            ]) => {
            return {
                portfolioDetails,
                exchangeRates,
                summary,
                assetAllocation,
                clientPositionsBrief,
                currencyAllocation,
                portfolioAnalysisTables,
                tradingTransactions,
                cashTransactions,
                allClientPositions,
                performance
            };
        });
    }

    getClientReportQuarterlyCharts(data: ClientReportQuarterlyDataCollection): Promise<ClientReportQuarterlyCharts> {
        const normalizeUnknown = (value: string | undefined) => value === '-' ? 'Not Specified' : (value || 'Not Specified');

        const clientPositionsAssetClassSunburstPlotPromise = (clientPositions: ClientPositionExtended[]) => {
            const assetClasses = new Set<string>();
            const assetSubClasses = new Set<string>();

            clientPositions.forEach(clientPosition => {
                assetClasses.add(normalizeUnknown(clientPosition.assetClass));
                assetSubClasses.add(normalizeUnknown(clientPosition.assetSubClass));
            });

            const parents: string[] = [];
            const values: number[] = [];
            const labels: string[] = [];
            const ids: string[] = [];
            const text: string[] = [];

            // First Circle: AssetClass
            assetClasses.forEach(assetClass => {
                const assetClassTotal = clientPositions
                    .filter(clientPosition => normalizeUnknown(clientPosition.assetClass) === assetClass)
                    .reduce((previousValue, currentValue) => previousValue + currentValue.portfolioPercentage, 0);

                parents.push("");  // Top-level nodes
                values.push(assetClassTotal);
                labels.push(assetClass);
                ids.push(assetClass);  // Unique ID for asset class
                text.push(`${assetClassTotal.toFixed(2)}%`);
            });

            // Second Circle: AssetSubClass
            assetSubClasses.forEach(assetSubClass => {
                const assetSubClassTotal = clientPositions
                    .filter(clientPosition => normalizeUnknown(clientPosition.assetSubClass) === assetSubClass)
                    .reduce((previousValue, currentValue) => previousValue + currentValue.portfolioPercentage, 0);

                const parentClass = normalizeUnknown(
                    clientPositions.find(clientPosition => normalizeUnknown(clientPosition.assetSubClass) === assetSubClass)?.assetClass
                );

                parents.push(parentClass);
                values.push(assetSubClassTotal);
                labels.push(normalizeUnknown(assetSubClass));
                ids.push(`${parentClass} - ${normalizeUnknown(assetSubClass)}`);
                text.push(`${assetSubClassTotal.toFixed(2)}%`);
            });

            return this.chartingModule.render(ombaSunburst(
                parents,
                labels,
                values,
                ids,
                {},
                text
            ));
        };

        const clientPositionsEtfSunburstPlotPromise = (clientPositions: ClientPositionExtended[]) => {
            const assetClasses = new Set<string>();
            const securityRics = new Set<string>();

            clientPositions.forEach(clientPosition => {
                assetClasses.add(normalizeUnknown(clientPosition.assetClass));
                securityRics.add(normalizeUnknown(clientPosition.securityRic));
            });

            const parents: string[] = [];
            const values: number[] = [];
            const labels: string[] = [];
            const ids: string[] = [];
            const text: string[] = [];

            // First Circle: AssetClass
            assetClasses.forEach(assetClass => {
                const assetClassTotal = clientPositions
                    .filter(clientPosition => normalizeUnknown(clientPosition.assetClass) === assetClass)
                    .reduce((previousValue, currentValue) => previousValue + currentValue.portfolioPercentage, 0);

                parents.push("");  // Top-level nodes
                values.push(assetClassTotal);
                labels.push(assetClass);
                ids.push(assetClass);  // Unique ID for asset class
                text.push(`${assetClassTotal.toFixed(2)}%`);
            });

            // Second Circle: SecurityRic
            clientPositions.forEach(clientPosition => {
                const assetClassId = normalizeUnknown(clientPosition.assetClass);
                const securityId = `${assetClassId}-${normalizeUnknown(
                    clientPosition.securityRic || clientPosition.securityName || clientPosition.securitySymbol || "Unknown"
                )}`;

                parents.push(assetClassId);  // Link to corresponding asset class
                values.push(clientPosition.portfolioPercentage);
                labels.push(clientPosition.securitySymbol || clientPosition.securityName || "Unknown");
                ids.push(securityId); // Add unique ID for SecurityRic
                text.push(`${(clientPosition.portfolioPercentage).toFixed(2)}%`);
            });

            console.log()

            return this.chartingModule.render(ombaSunburst(
                parents,
                labels,
                values,
                ids,
                {}, // Options
                text
            ));
        };


        const performanceLineChartPlotPromise = (clientPerformance: PerformanceMetric[]) => {
            return this.chartingModule.render(ombaLine(
                [
                    {
                        x: clientPerformance.map((metric) => metric.effectiveAt),
                        y: clientPerformance.map((metric) => 100 + (metric.oneDay || 0)),
                        line: {
                            color: '#255025',
                            width: 8
                        },
                        name: 'Portfolio'
                    },
                    {
                        x: clientPerformance.map((metric) => metric.effectiveAt),
                        y: clientPerformance.map((metric) => 100 + (metric.benchmarkOneDay || 0)),
                        line: {
                            color: '#7f6a46',
                            width: 8
                        },
                        name: 'Benchmark'
                    }
                ],
                {
                    showlegend: true,
                    legend: {
                        x: 0.5,
                        xanchor: "center",
                        y: 0.1,
                        orientation: "h",
                        font: {
                            size: 30,
                        }
                    },
                    plot_bgcolor: '#f4f0ec'
                },
                {
                    tickangle: 270,
                    tickfont: {
                        size: 30
                    },
                    showgrid: false
                },
                {
                    tickfont: {
                        size: 30
                    },
                    showgrid: false
                },
                2
            ));
        };

        return Promise.all([this.getPortfolioAnalysisReportCharts(data.portfolioAnalysis), clientPositionsAssetClassSunburstPlotPromise(data.clientPositions), clientPositionsEtfSunburstPlotPromise(data.clientPositions), performanceLineChartPlotPromise(data.performance)]).then(([portfolioAnalysisCharts, clientPositionsAssetClassSunburstPlot, clientPositionsEtfSunburstPlot, performanceLineChartPlot]) => ({
          ...portfolioAnalysisCharts,
          clientPositionsAssetClassSunburst: {
            image: clientPositionsAssetClassSunburstPlot
          },
          clientPositionsEtfSunburst: {
            image: clientPositionsEtfSunburstPlot
          },
          performanceLineChart: {
            image: performanceLineChartPlot
          }
        }));
    }
}

export default ClientReportQuarterlyDataViewModel;