import ClientPosition from "../models/clientPositions/clientPosition";
import ClientPositionWithLookThrough from "../models/clientPositions/clientPositionWithLookThrough";
import InstrumentViewModel from "./instrumentViewModel";
import ClientPositionsViewModel from "./clientPositionsViewModel";
import EtfPositionsViewModel from "./etfPositionsViewModel";
import PortfolioAnalysisReportDataCollection from "../interfaces/portfolioAnalysisReportDataCollection"
import PortfolioAnalysisCharts from "../interfaces/props/reports/portfolioAnalysisCharts";
import InstrumentType from "../interfaces/enums/instrumentType";
import Attributes from "../interfaces/enums/attributes"
import Instrument from "../models/instruments/instrument";
import FullyEnrichedClientPosition from "../interfaces/fullyEnrichedClientPosition";
import prettify from "../utilities/prettify";
import chartColours from "../charting/chartColours";
import {Annotations, Color, Data, PlotData} from "plotly.js";
import EtfPositionWithInstrument from "../interfaces/etfPositionWithInstrument";
import EtfPosition from "../models/etfPositions/etfPosition";
import ChartingModule from "../interfaces/modules/chartingModule";
import PortfolioAnalysisTables from "../interfaces/props/reports/portfolioAnalysisTables";
import DataTableData from "../interfaces/datatable";
import ombaSunburst from "../charting/sunburst";
import ombaHeatmap from "../charting/heatmap";
import ombaMap from "../charting/map";
import ombaStackedBar from "../charting/stackedBar";
import ombaGroupedBar from "../charting/groupedBar";
import CountryMapping from "../constants/countryMapping";
import CreditRatingMapping from "../constants/creditRatingMapping";
import formatWeight from "../utilities/formatWeight";
import colourPicker from "../utilities/colourPicker";

type LoggingModule = (warningLabel: string, warningMessage: string) => void;

class PortfolioAnalysisReportDataViewModel{

    private instrumentViewModel: InstrumentViewModel;
    
    clientPositionsViewModel: ClientPositionsViewModel;
    
    private etfPositionsViewModel: EtfPositionsViewModel;

    chartingModule: ChartingModule;

    loggingModule?: LoggingModule;

    private instrumentAttributes = [
        Attributes.Instrument.Refinitiv.InstrumentDomicile,
        Attributes.Instrument.Refinitiv.TotalExpenseRatio,
        Attributes.Instrument.Eikon.FiIssuerName,
        Attributes.Instrument.Eikon.HeadquartersCountry,
        Attributes.Instrument.Eikon.ExchangeCountry,
        Attributes.Instrument.Eikon.GICSSector,
        Attributes.Instrument.Eikon.GICSIndustryGroup,
        Attributes.Instrument.Eikon.GICSIndustry,
        Attributes.Instrument.Eikon.ForwardPERatio,
        Attributes.Instrument.Eikon.ForecastDividendYield,
        Attributes.Instrument.Eikon.FourWeekPriceChange,
        Attributes.Instrument.Eikon.CompanyMarketCap,
        Attributes.Instrument.Eikon.YieldToMaturity,
        Attributes.Instrument.Eikon.YieldToWorst,
        Attributes.Instrument.Eikon.ModifiedDuration,
        Attributes.Instrument.Eikon.RatingSPEquivalent,
        Attributes.Instrument.Eikon.FiSPRating,
        Attributes.Instrument.Eikon.FiMoodysRating
    ];
    
    constructor(
        instrumentViewModel: InstrumentViewModel,
        clientPositionsViewModel: ClientPositionsViewModel,
        etfPositionsViewModel: EtfPositionsViewModel,
        chartingModule: ChartingModule
    ) {
        this.instrumentViewModel = instrumentViewModel;
        this.clientPositionsViewModel = clientPositionsViewModel;
        this.etfPositionsViewModel = etfPositionsViewModel;
        this.chartingModule = chartingModule;
    }

    private emitLog(warningLabel: string, warningMessage: string) : void{
        if ((this.loggingModule == null) || (this.loggingModule == undefined)){
            return
        }
        this.loggingModule(warningLabel, warningMessage);
    }

    private NotSpecified = "Not Specified";

    private getColourName(hexCode: string) : string{
        return chartColours[hexCode];
    };

    private getEtfsByAssetClass(fullyEnrichedClientPositions: FullyEnrichedClientPosition[], assetClass: InstrumentType): FullyEnrichedClientPosition[]{
        return fullyEnrichedClientPositions.filter(fullyEnrichedClientPosition => fullyEnrichedClientPosition.clientPosition.assetClass === assetClass)
    };

    /**
     * We need to convert the chart data to a different format to be able to construct the commentary, the format
     * returned by this function gives us the totals for each bar broken down by the contributor so we can answer
     * questions such as "Which Sector is the biggest in your Portfolio" and "What was the biggest contributor to
     * that sector?"
     *
     *
     * @param chartData
     * @constructor
     */
    private prepareForCommentary(chartData: PlotData[]): Promise<[{[key: string]: [string, number, string][]}, [string, number][]]>{

        let data: {[key: string]: [string, number, string][]} = {};

        chartData.forEach(trace => {
            trace.x.forEach((tracePoint, index) => {

                let tracePointString = tracePoint as string;

                if (!(tracePointString in data)){
                    data[tracePointString] = []
                }

                data[tracePointString].push([trace.name, trace.y[index] as number, trace.marker.color as string]);
            });
        });

        Object.keys(data).forEach(key => {
            data[key].sort(([instrumentIdA, totalA], [instrumentIdB, totalB]) => totalB - totalA);
        });

        const dataTotals =
            Object.entries(data).map(([key, values]) => {
                return [key, values.reduce((previousValue, [instrumentId, value]) => previousValue + value, 0)] as [string, number]
            }).sort(([keyA, totalA], [keyB, totalB]) => (totalB as number) - (totalA as number));

        return new Promise((resolve) => resolve([data, dataTotals]));
    };

    private getTopEtfHoldings(fullyEnrichedClientPositions: FullyEnrichedClientPosition[], top: number) : EtfPositionWithInstrument[] {
        // Dictionary to aggregate by unique holding identifier (e.g., underlyingInstrumentDescription)
        const aggregatedHoldings: { [key: string]: { relativeWeight: number, etfPositionWithInstrument: EtfPositionWithInstrument } } = {};

        fullyEnrichedClientPositions.forEach(clientPosition => {
            const etfWeight = clientPosition.clientPosition.calculateWeight(fullyEnrichedClientPositions.map(pos => pos.clientPosition)); // Calculate ETF weight

            clientPosition.etfPositions.forEach(position => {
                const holdingWeight = position.etfPosition?.weight || 0; // Weight of the holding inside the ETF
                const relativeWeight = etfWeight * holdingWeight; // Calculate relative weight (adjusted)

                const holdingKey = position.etfPosition.underlyingInstrumentDescription || position.etfPosition.underlyingInstrumentName || this.NotSpecified;

                // Check if the holding already exists in the dictionary
                if (aggregatedHoldings[holdingKey]) {
                    // If exists, sum the relative weights
                    aggregatedHoldings[holdingKey].relativeWeight += relativeWeight;
                } else {
                    // Otherwise, add the holding with its relative weight
                    aggregatedHoldings[holdingKey] = {
                        relativeWeight: relativeWeight, // Initialize the relative weight
                        etfPositionWithInstrument: position // Keep the EtfPositionWithInstrument reference
                    };
                }
            });
        });

        // Convert the aggregated holdings dictionary back into an array of EtfPositionWithInstrument
        const aggregatedArray = Object.values(aggregatedHoldings).map(holding => {
            return {
                ...holding.etfPositionWithInstrument, // Copy the EtfPositionWithInstrument
                relativeWeight: holding.relativeWeight // Attach the aggregated relative weight
            };
        });

        // Sort by relative weight in descending order and take the top n
        const topEtfPositions = aggregatedArray
            .sort((a, b) => (b.relativeWeight || 0) - (a.relativeWeight || 0)) // Sort by relative weight
            .slice(0, top); // Take the top 'n' entries

        return topEtfPositions;
    }

    private combineCash(fullyEnrichedClientPositions: FullyEnrichedClientPosition[]): any{
        let combined = this.getEtfsByAssetClass(fullyEnrichedClientPositions, InstrumentType.Cash).reduce(
                (prev, current) => {
                    prev.marketValue += current.clientPosition.marketValueReportingCurrency;
                    prev.weight += current.clientPosition.calculateWeight(fullyEnrichedClientPositions.map(holding => holding.clientPosition));
                    return prev;
                }, {
                    "marketValue": 0,
                    "weight": 0
                }
            );

        return {
            "ticker": "-",
            "name": "Cash (in Omba-viewed accounts only)",
            "domicile": "-",
            "expenseRatio": "-",
            "type": InstrumentType.Cash.toString(),
            "shares": "-",
            "price": "-",
            "marketValue": prettify(combined.marketValue.toFixed(2)),
            "weight": formatWeight(combined.weight)
        };
    };

    private getEtfsExcludingAssetClass(fullyEnrichedClientPositions: FullyEnrichedClientPosition[], assetClass: InstrumentType): FullyEnrichedClientPosition[]{
        return fullyEnrichedClientPositions.filter(fullyEnrichedClientPosition => fullyEnrichedClientPosition.clientPosition.assetClass !== assetClass)
    };

    /**
     * Takes a client's ETF Positions, the holdings of those ETFs and the underlying instrument data for both the
     * ETFs and their underlying holdings and combines them into a single data structure for easier access
     *
     * @param {ClientPosition[]} clientPositions The client's ETF positions for a given date
     * @param {EtfPosition[]} etfData The holdings for the ETFs
     * @param {{[instrumentId: string]: Instrument}} instruments The underlying instruments for all ETFs and underlying holdings
     * @param {string} warningPrefix The prefix to apply to any warnings raised
     * @return {FullyEnrichedClientPosition[]} The combined data containing all client positions and for each client position
     * the instrument for that ETF, all the ETFs holdings and associated instruments.
     */
    private combineData(
        clientPositions: ClientPosition[],
        etfData: EtfPosition[],
        instruments: {[instrumentId: string]: Instrument},
        dateType: string
    ): FullyEnrichedClientPosition[]{

        if (clientPositions.length === 0){
            this.emitLog("Missing Client Positions", `No Client Positions at ${dateType}`);
            return [];
        }

        // For each client position
        return clientPositions.map(clientPosition => {

            if (clientPosition.assetClass === InstrumentType.Cash){
                return {
                    clientPosition: clientPosition,
                    etfPositions: [] // Cash is not an ETF and therefore has no holdings
                };
            }

            // Get the associated holdings of that ETF
            let etfHoldings = etfData.filter(etfHolding => etfHolding.providedEtfId == clientPosition.securityIsin);

            // There could be none
            if (etfHoldings.length == 0 || (etfHoldings.length == 1 && etfHoldings[0].etfIdentifierValue == null)){
                this.emitLog("Missing ETF Positions", `No underlying ETF Positions found for ETF held by client with id of ISIN/${clientPosition.securityIsin}, RIC/${clientPosition.securityRic} at ${dateType}`);
                return {
                    clientPosition: clientPosition,
                    etfPositions: []
                }
            }

            // Get the instrument for the ETF
            var etfInstrument: Instrument | undefined = undefined;

            if (etfHoldings[0]?.etfId != undefined) {
                etfInstrument = instruments[etfHoldings[0].etfId];
            } else {
                this.emitLog("Missing ETF Instruments", `No ETF instrument found for ETF held by client with id of ISIN/${clientPosition.securityIsin}, RIC/${clientPosition.securityRic} at ${dateType}`)
            }

            return {
                clientPosition: clientPosition,
                instrument: etfInstrument,
                etfPositions: etfHoldings.map(etfHolding => {

                    if (etfHolding.underlyingInstrumentId == null || !(etfHolding.underlyingInstrumentId in instruments)){
                        this.emitLog("Missing underlying Instruments", `No instrument found for etfPosition with providedEtfId of ${etfHolding.providedEtfId} and underlyingInstrumentId of ${etfHolding.instrumentIdentifierType}/${etfHolding.instrumentIdentifierValue} at ${dateType}`)
                        return {
                            etfPosition: etfHolding,
                            instrument: new Instrument("Unresolved", InstrumentType.Equity, "USD", {})
                        }
                    }

                    return {
                        etfPosition: etfHolding,
                        instrument: instruments[etfHolding.underlyingInstrumentId]
                    }
                })
            }
        })
    };

    getPortfolioAnalysisReportCharts(clientHoldingsFullyEnriched: PortfolioAnalysisReportDataCollection): Promise<PortfolioAnalysisCharts>{

        //#region "ClientHoldingsSunburst"
        const clientHoldingsSunburstPlotPromise = (clientHoldings: FullyEnrichedClientPosition[]) => {
            let holdingTypes = new Set<string>();

            clientHoldings.map(holding => {
                if (holdingTypes.has(holding.clientPosition.assetClass || "Unknown")){
                    return
                }
                holdingTypes.add(holding.clientPosition.assetClass || "Unknown")
            });

            // Inner Circle
            let total = clientHoldings.reduce(((previousValue, currentValue) => previousValue + currentValue.clientPosition.marketValueReportingCurrency), 0);
            let parents = [""];
            let values = [total];
            let labels = ["Portfolio"];
            let text = [""];

            // Second Circle
            holdingTypes.forEach(holdingType => {

                let subTotal = clientHoldings
                    .filter(holding => (holding.clientPosition.assetClass || "Unknown") == holdingType)
                    .reduce(((previousValue, currentValue) => previousValue + currentValue.clientPosition.marketValueReportingCurrency), 0);

                parents.push("Portfolio");
                values.push(subTotal);
                labels.push(holdingType);
                text.push(`${(subTotal / total * 100).toFixed(2)}%`)
            });

            // Outside Circle
            clientHoldings.forEach(holding => {
                parents.push(holding.clientPosition.assetClass || "Unknown");
                values.push(holding.clientPosition.marketValueReportingCurrency);
                labels.push(holding.clientPosition.securitySymbol || holding.clientPosition.securityName);
                text.push(`${(holding.clientPosition.marketValueReportingCurrency / total * 100).toFixed(2)}%`)
            });

            return this.chartingModule.render(ombaSunburst(
                parents,
                labels,
                values,
                labels,
                {},
                text
            ));
            //#endregion
        };
        // #endregion

        //#region "IndustrySectorSunburst"
        const equitySectorIndustryExposureSunburstChartIncludingCommentaryPromise = (clientHoldings: FullyEnrichedClientPosition[]) => {
            let sectors = new Set<string>();
            let industries = new Set<string>();

            clientHoldings.map(holding => {
                holding.etfPositions.map(etfPosition => {
                    let positionSector = etfPosition?.instrument.getAttributeValue(Attributes.Instrument.Eikon.GICSSector, this.NotSpecified);
                    let positionIndustry = etfPosition?.instrument.getAttributeValue(Attributes.Instrument.Eikon.GICSIndustry, "Not Specified Either");

                    if (!sectors.has(positionSector)){
                        sectors.add(positionSector)
                    }

                    if (!industries.has(`${positionIndustry}/${positionSector}`)){
                        industries.add(`${positionIndustry}/${positionSector}`)
                    }

                    return;
                })
            });

            // Inner Circle
            let total = clientHoldings.reduce(
                ((previousValue, currentValue) => previousValue + currentValue.etfPositions.reduce(
                        (previousValue2, currentValue2) => previousValue2 + ((currentValue2.etfPosition.weight || 0) * currentValue.clientPosition.marketValueReportingCurrency), 0)
                ), 0
            );

            let ids = ["Portfolio"];
            let parents = [""];
            let values = [total];
            let labels = ["Portfolio"];
            let text = [""];

            var biggestSector: [string, number] = [this.NotSpecified, 0];
            var biggestIndustry: [string, number] = [this.NotSpecified, 0];

            // Second Circle
            sectors.forEach(sector => {

                let subTotal = clientHoldings.reduce(
                    ((previousValue, currentValue) => previousValue + currentValue.etfPositions
                            .filter(etfPosition => (etfPosition?.instrument.getAttributeValue(Attributes.Instrument.Eikon.GICSSector) || this.NotSpecified) == sector)
                            .reduce(
                                (previousValue2, currentValue2) => previousValue2 + ((currentValue2.etfPosition.weight || 0) * currentValue.clientPosition.marketValueReportingCurrency), 0)
                    ), 0
                );

                if (subTotal > biggestSector[1]){
                    biggestSector = [sector, subTotal]
                }

                ids.push(sector);
                parents.push("Portfolio");
                values.push(subTotal);
                labels.push(sector);
                text.push(`${(subTotal / total * 100).toFixed(2)}%`)
            });

            // Outer Circle
            industries.forEach(industryWithSector => {
                let [industry, sector] = industryWithSector.split("/");

                let subTotal = clientHoldings.reduce(
                    ((previousValue, currentValue) => previousValue + currentValue.etfPositions
                            .filter(
                                etfPosition => (etfPosition?.instrument.getAttributeValue(Attributes.Instrument.Eikon.GICSIndustry, "Not Specified Either") || "Not Specified Either") == industry
                                    && (etfPosition?.instrument.getAttributeValue(Attributes.Instrument.Eikon.GICSSector) || this.NotSpecified) == sector)
                            .reduce(
                                (previousValue2, currentValue2) => previousValue2 + ((currentValue2.etfPosition.weight || 0) * currentValue.clientPosition.marketValueReportingCurrency), 0)
                    ), 0
                );

                if (sector == biggestSector[0] && subTotal > biggestIndustry[1]){
                    biggestIndustry = [industry, subTotal]
                }

                ids.push(industryWithSector);
                parents.push(sector);
                values.push(subTotal);
                labels.push(industry);
                text.push(`${(subTotal / total * 100).toFixed(2)}%`)
            });

            return [
                this.chartingModule.render(ombaSunburst(
                    parents,
                    labels,
                    values,
                    ids,
                    {},
                    text,
                    0.75)
                ),
                new Promise<string>(resolve => {
                    return resolve(`The chart below shows the sector and industry breakdown of the equity portion of your current portfolio. The largest exposure is to ${biggestSector[0]} (${Math.round(biggestSector[1] / total * 100 * 100) / 100}%) and within that ${Math.round(biggestIndustry[1] / total * 100 * 100) / 100}% exposure is to ${biggestIndustry[0]}`)
                })
            ];
        };
        //#endregion

        //#region "etfEquitySectorExposureBarChart" / "etfEquityIndustryExposureBarChart" / "etfEquityCompanyExposureBarChart" / "etfEquityHeadquartersExposureBarChart"
        const equityBarChartExposures = (clientHoldings: FullyEnrichedClientPosition[]) => {

            let industryData: Data[] = [];
            let sectorData: Data[] = [];
            let companyData: Data[] = [];
            let headquartersData: Data[] = [];

            clientHoldings.forEach((holding, index) => {

                let sectorTotals: { [sector: string]: number } = {};
                let industryTotals: { [industry: string]: number } = {};
                let companyTotals: { [company: string]: number } = {};
                let headquartersTotals: { [headquarters: string]: number } = {};

                holding.etfPositions.forEach(etfHolding => {

                    let sector = etfHolding?.instrument.getAttributeValue(Attributes.Instrument.Eikon.GICSSector);
                    let industry = etfHolding?.instrument.getAttributeValue(Attributes.Instrument.Eikon.GICSIndustry);
                    let company = etfHolding?.instrument.description || "Unknown";
                    let headquarters = CountryMapping[etfHolding?.instrument.getAttributeValue(Attributes.Instrument.Eikon.HeadquartersCountry, "")]?.SimplifiedName;

                    let weight = (etfHolding.etfPosition.weight || 0) * holding.clientPosition.calculateWeight(clientHoldings.map(holding => holding.clientPosition));

                    if (sector in sectorTotals){
                        sectorTotals[sector] += weight;
                    } else {
                        sectorTotals[sector] = weight;
                    }

                    if (industry in industryTotals){
                        industryTotals[industry] += weight;
                    } else {
                        industryTotals[industry] = weight;
                    }

                    if (company in companyTotals){
                        companyTotals[company] += weight;
                    } else {
                        companyTotals[company] = weight;
                    }

                    if (headquarters in headquartersTotals){
                        headquartersTotals[headquarters] += weight;
                    } else {
                        headquartersTotals[headquarters] = weight;
                    }

                });

                sectorData.push({
                    x: Object.keys(sectorTotals),
                    y: Object.values(sectorTotals),
                    name: holding?.instrument?.identifiers?.KeyRIC || "Unknown",
                    marker: {
                        color: colourPicker(index, chartColours)
                    },
                    type: 'bar'
                });

                industryData.push({
                    x: Object.keys(industryTotals),
                    y: Object.values(industryTotals),
                    name: holding?.instrument?.identifiers?.KeyRIC || "Unknown",
                    marker: {
                        color: colourPicker(index, chartColours)
                    },
                    type: 'bar'
                });

                companyData.push({
                    x: Object.keys(companyTotals),
                    y: Object.values(companyTotals),
                    name: holding?.instrument?.identifiers?.KeyRIC || "Unknown",
                    marker: {
                        color: colourPicker(index, chartColours)
                    },
                    type: 'bar'
                });

                headquartersData.push({
                    x: Object.keys(headquartersTotals),
                    y: Object.values(headquartersTotals),
                    name: holding?.instrument?.identifiers?.KeyRIC || "Unknown",
                    marker: {
                        color: colourPicker(index, chartColours)
                    },
                    type: 'bar'
                });
            });


            const etfEquitySectorExposureBarChartPromise = this.chartingModule.render(
                ombaStackedBar(
                    sectorData,
                    {},
                    1.8
                )
            );

            const etfEquitySectorExposureBarChartCommentaryPromise: Promise<string> = this.prepareForCommentary(
                sectorData as PlotData[]).then(([sectorBreakdown, sectorTotals]) => {

                if (Object.keys(sectorTotals).length == 0){
                    return new Promise(resolve => resolve("No data"))
                }

                let largestSector = sectorTotals[0];

                return new Promise((resolve) => {
                    resolve(`The chart below shows the various sectors to which each of the equity ETFs are exposed. Your largest allocation is to the ${largestSector[0]} sector with ${Math.round(largestSector[1] * 100)/100}% of your Portfolio exposed to this sector. The largest contributor to the ${largestSector[0]} sector is ${sectorBreakdown[largestSector[0]][0][0]} with ${Math.round(sectorBreakdown[largestSector[0]][0][1] * 100)/100}% shown by the ${this.getColourName(sectorBreakdown[largestSector[0]][0][2])} coloured bar below.`)
                });
            });

            const etfEquityIndustryExposureBarChartPromise = this.chartingModule.render(
                ombaStackedBar(
                    industryData,
                    {},
                    2,
                    1000
                )
            );

            const etfEquityIndustryExposureBarChartCommentaryPromise: Promise<string> = this.prepareForCommentary(
                industryData as PlotData[]).then(([industryBreakdown, industryTotals]) => {

                if (Object.keys(industryTotals).length == 0){
                    return new Promise(resolve => resolve("No data"))
                }

                if (Object.keys(industryTotals).length == 1){
                    return new Promise(resolve => resolve(`The chart on the left shows the industry level exposure of your portfolio, with each coloured bar representing the contribution by each of your different ETFs. Your highest exposure is to ${industryTotals[0][0]}.`))
                }

                return new Promise(resolve => resolve(`The chart on the left shows the industry level exposure of your portfolio, with each coloured bar representing the contribution by each of your different ETFs. Your highest exposure is to ${industryTotals[0][0]} followed by ${industryTotals[1][0]}`))
            });

            const etfEquityCompanyExposureBarChartPromise = this.chartingModule.render(
                ombaStackedBar(
                    companyData,
                    {
                        plot_bgcolor: 'white',
                    },
                    2,
                    800
                )
            );


            const etfEquityCompanyExposureBarChartCommentaryPromise: Promise<string> = this.prepareForCommentary(
                companyData as PlotData[]).then(([companyBreakdown, companyTotals]) => {

                if (Object.keys(companyTotals).length == 0){
                    return new Promise(resolve => resolve("No data"))
                }

                let largestComapny = companyTotals[0];

                return new Promise((resolve) => {
                    resolve(`The chart below shows the largest (indirect) holdings in your portfolio. Your largest holding is ${largestComapny[0]} at which is just over ${Math.round(largestComapny[1] * 100)/100}% of the entire equity portion of your portfolio.`)
                });
            });

            const etfEquityHeadquartersExposureBarChartPromise = this.chartingModule.render(
                ombaStackedBar(
                    headquartersData,
                    {
                        plot_bgcolor: 'white',
                    },
                    2,
                    800
                )
            );

            const etfEquityHeadquartersExposureBarChartCommentaryPromise: Promise<string> = this.prepareForCommentary(
                headquartersData as PlotData[]).then(([headquartersBreakdown, headquartersTotals]) => {

                if (Object.keys(headquartersTotals).length == 0){
                    return new Promise(resolve => resolve("No data"))
                }

                let largestHeadquarters = headquartersTotals[0];

                return new Promise((resolve) => {
                    resolve(`The chart below shows the largest (indirect) country exposure for the equity portion of your portfolio. Your largest exposure (as measured by Country of Headquarters of each of the underlying companies) is ${largestHeadquarters[0]} at ${Math.round(largestHeadquarters[1] * 100)/100}%.`)
                });
            });

            return [
                etfEquitySectorExposureBarChartPromise,
                etfEquitySectorExposureBarChartCommentaryPromise,
                etfEquityIndustryExposureBarChartPromise,
                etfEquityIndustryExposureBarChartCommentaryPromise,
                etfEquityCompanyExposureBarChartPromise,
                etfEquityCompanyExposureBarChartCommentaryPromise,
                etfEquityHeadquartersExposureBarChartPromise,
                etfEquityHeadquartersExposureBarChartCommentaryPromise
            ]
        };
        //#endregion

        //#region "etfEquityHeaderquartersExposureByEtf"
        const equityHeadquartersExposureByEtf = (clientHoldings: FullyEnrichedClientPosition[]) => {

            let headquartersCountryByEtf: { [headquarters: string]: { [etfRic: string]: number } } = {};

            clientHoldings.forEach((holding, index) => {
                holding.etfPositions.forEach(etfHolding => {

                    let ric = holding?.instrument?.identifiers?.KeyRIC || "Unknown";
                    let headquarters = CountryMapping[etfHolding?.instrument.getAttributeValue(Attributes.Instrument.Eikon.HeadquartersCountry, "")]?.SimplifiedName;
                    let weight = (etfHolding.etfPosition.weight || 0);

                    if (headquartersCountryByEtf[headquarters] == null){
                        headquartersCountryByEtf[headquarters] = {};
                    }

                    if (ric in headquartersCountryByEtf[headquarters]){
                        headquartersCountryByEtf[headquarters][ric] += weight;
                    } else {
                        headquartersCountryByEtf[headquarters][ric] = weight;
                    }
                })
            });

            let headquartersByEtfData = Object.entries(headquartersCountryByEtf).map(([key, value], index) => {
                return {
                    x: Object.keys(value),
                    y: Object.values(value),
                    name: key,
                    marker: {
                        color: colourPicker(index, chartColours)
                    },
                    type: 'bar'
                } as Data
            });

            const etfEquityHeadquartersExposureByEtfBarChartPromise = this.chartingModule.render(
                ombaStackedBar(
                    headquartersByEtfData,
                    {},
                    1.8
                )
            );

            const etfEquityHeadquartersExposureByEtfBarChartCommentaryPromise: Promise<string> = this.prepareForCommentary(
                headquartersByEtfData as PlotData[]).then(([headquartersBreakdown, etfTotals]) => {

                if (Object.keys(etfTotals).length == 0){
                    return new Promise(resolve => resolve("No data"))
                }

                let etfWithMostCountries = Object.keys(headquartersBreakdown).reduce((previousValue, currentValue) => {
                    return headquartersBreakdown[currentValue].length > headquartersBreakdown[previousValue].length ? currentValue : previousValue
                });

                let commentary = `The chart below shows the Country of Headquarters exposure for each of your equity ETFs in your portfolio. Only the largest 15 countries are shown and therefore some ETFs do not total 100%. For example ${etfWithMostCountries} has a ${Math.round(headquartersBreakdown[etfWithMostCountries][0][1] * 100) / 100}% exposure to ${headquartersBreakdown[etfWithMostCountries][0][0]}`;

                if (headquartersBreakdown[etfWithMostCountries].length == 1){
                    commentary += `.`
                } else if (headquartersBreakdown[etfWithMostCountries].length == 2){
                    commentary += ` and ${Math.round(headquartersBreakdown[etfWithMostCountries][1][1] * 100) / 100}% exposure to ${headquartersBreakdown[etfWithMostCountries][1][0]}.`
                }
                else if (headquartersBreakdown[etfWithMostCountries].length == 3)
                {
                    commentary += `, ${Math.round(headquartersBreakdown[etfWithMostCountries][1][1] * 100) / 100}% exposure to ${headquartersBreakdown[etfWithMostCountries][1][0]} and a ${Math.round(headquartersBreakdown[etfWithMostCountries][2][1] * 100) / 100}% exposure to ${headquartersBreakdown[etfWithMostCountries][2][0]}.`
                }
                else{
                    commentary += `, ${Math.round(headquartersBreakdown[etfWithMostCountries][1][1] * 100) / 100}% exposure to ${headquartersBreakdown[etfWithMostCountries][1][0]} and a ${Math.round(headquartersBreakdown[etfWithMostCountries][2][1] * 100) / 100}% exposure to ${headquartersBreakdown[etfWithMostCountries][2][0]}, with other countries making up the balance of the holdings.`
                }
                return new Promise((resolve) => {
                    resolve(commentary)
                });
            });

            return [
                etfEquityHeadquartersExposureByEtfBarChartPromise,
                etfEquityHeadquartersExposureByEtfBarChartCommentaryPromise
            ]
        };

        //#endregion

        //#region "etfEquityHeadquartersExposurePortfolioMap"
        const equityHeadquartersExposureMap = (clientHoldings: FullyEnrichedClientPosition[]) => {
            let headquartersCountryByEtfMap: { [headquarters: string]: number } = {};

            clientHoldings.forEach((holding) => {
                holding.etfPositions.forEach(etfHolding => {

                    let headquarters = CountryMapping[etfHolding?.instrument.getAttributeValue(Attributes.Instrument.Eikon.HeadquartersCountry, "")]?.SimplifiedName;
                    let weight = (etfHolding.etfPosition.weight || 0) * holding.clientPosition.calculateWeight(clientHoldings.map(holding => holding.clientPosition));
                    if (headquarters in headquartersCountryByEtfMap){
                        headquartersCountryByEtfMap[headquarters] += weight;
                    } else {
                        headquartersCountryByEtfMap[headquarters] = weight;
                    }
                })
            });

            return this.chartingModule.render(
                ombaMap(
                    [
                        {
                            locations: Object.keys(headquartersCountryByEtfMap),
                            z: Object.values(headquartersCountryByEtfMap),
                            text: Object.keys(headquartersCountryByEtfMap),
                            locationmode: 'country names',
                            colorscale: [
                                [0, "rgb(241, 243, 241)"],
                                [1, "rgb(51, 77, 49)"]
                            ],
                            marker: {
                                line: {
                                    color: 'rgb(51,77,49)',
                                    width: 1
                                },
                                colorbar: {
                                    title: "%",
                                    outlinecolor: 'rgb(255,255,255)',
                                    thickness: 12
                                }
                            },
                            type: 'choropleth'
                        }
                    ],
                    {
                    },
                    1.8
                )
            );
        };

        //#endregion

        //#region EtfFixedIncomeRatings
        const fixedIncomeRatingsChartWithCommentary = (clientHoldings: FullyEnrichedClientPosition[]): [Promise<string>, Promise<string>]  => {
            let ratingData: Data[] = [];

            clientHoldings.forEach((holding, index) => {

                let ratingTotals: { [sector: string]: number } = {};

                holding.etfPositions.forEach(etfHolding => {

                    let rating = etfHolding?.instrument.getAttributeValueWaterfall([
                        Attributes.Instrument.Eikon.RatingSPEquivalent,
                        Attributes.Instrument.Eikon.FiSPRating,
                        Attributes.Instrument.Eikon.FiMoodysRating
                    ], "Not Rated");

                    if (rating in CreditRatingMapping){
                        rating = CreditRatingMapping[rating]["TranslatedRating"]
                    }

                    let weight = (etfHolding.etfPosition.weight || 0);

                    if (rating in ratingTotals){
                        ratingTotals[rating] += weight;
                    } else {
                        ratingTotals[rating] = weight;
                    }

                });

                ratingData.push({
                    x: Object.keys(ratingTotals),
                    y: Object.values(ratingTotals),
                    name: holding?.instrument?.identifiers?.KeyRIC || "Not Rated",
                    marker: {
                        color: colourPicker(index, chartColours)
                    },
                    type: 'bar'
                });
            });

            return [
                this.chartingModule.render(
                    ombaGroupedBar(
                        ratingData,
                        {
                        },
                        {
                        }
                    )
                ),
                new Promise<string>((resolve) => resolve("The chart below show the credit rating of the holdings within each of your ETFs."))
                ];
        };
        //#endregion

        //#region EquityOverlap
        const etfOverlapHeatmap = (overlap: {[etfId: string]: {[etfId: string]: number}}): [Promise<string>, Promise<string>] => {
            let z: number[][] = [];
            let x = Array.from(Object.keys(overlap));
            let y: string[] = [];
            let annotations: Array<Partial<Annotations>> = [];

            Object.keys(overlap).forEach(etfId => {
                y.push(etfId);
                z.push(x.map(etf => {
                    annotations.push({
                        x: etf,
                        y: etfId,
                        text: overlap[etfId][etf] === 0 ? "-" : overlap[etfId][etf].toFixed(1),
                        showarrow: false,
                    });
                    return overlap[etfId][etf]
                }));
            });

            let data: Data[] = [
                {
                    z: z,
                    x: x,
                    y: y,
                    type: 'heatmap',
                    colorscale: [
                        [0, '#E4E4E0'],
                        [1, '#525E48']
                    ]
                }
            ];

            return [
                this.chartingModule.render(
                    ombaHeatmap(
                        data,
                        {
                            annotations: annotations
                        },
                        1.4
                    )
                ),
                new Promise((resolve) => resolve("These tables show the level of overlap between any two ETFs. For example, if two ETFs both own Microsoft Corporation, one at 4% and the other at 5%, the matrix below will show 4% at their intersection, as that is how much they overlap one another."))
            ]
        };
        //#endregion

        const [
            equitySectorIndustryExposureSunburstChartPromise,
            equitySectorIndustryExposureSunburstChartCommentaryPromise
        ] = equitySectorIndustryExposureSunburstChartIncludingCommentaryPromise(this.getEtfsByAssetClass(clientHoldingsFullyEnriched.reportDate.clientPositions, InstrumentType.Equity));

        const [
            etfEquitySectorExposureBarChartPromise,
            etfEquitySectorExposureBarChartCommentaryPromise,
            etfEquityIndustryExposureBarChartPromise,
            etfEquityIndustryExposureBarChartCommentaryPromise,
            etfEquityCompanyExposureBarChartPromise,
            etfEquityCompanyExposureBarChartCommentaryPromise,
            etfEquityHeadquartersExposureBarChartPromise,
            etfEquityHeadquartersExposureBarChartCommentaryPromise
        ] = equityBarChartExposures(this.getEtfsByAssetClass(clientHoldingsFullyEnriched.reportDate.clientPositions, InstrumentType.Equity));

        const [
            etfEquityHeadquartersExposureByEtfBarChartPromise,
            etfEquityHeadquartersExposureByEtfBarChartCommentaryPromise
        ] = equityHeadquartersExposureByEtf(this.getEtfsByAssetClass(clientHoldingsFullyEnriched.reportDate.clientPositions, InstrumentType.Equity));

        const [
            etfOveralHeatmapPromise,
            etfOverallHeatapWithCommentaryPromise
        ] = etfOverlapHeatmap(clientHoldingsFullyEnriched.reportDate.overlap);

        const [
            fixedIncomeRatingsChart,
            fixedIncomeRatingsChartCommentary
        ] = fixedIncomeRatingsChartWithCommentary(this.getEtfsByAssetClass(clientHoldingsFullyEnriched.reportDate.clientPositions, InstrumentType.FixedIncome));

        return Promise.all([
            clientHoldingsSunburstPlotPromise(this.getEtfsExcludingAssetClass(clientHoldingsFullyEnriched.reportDate.clientPositions, InstrumentType.Cash)),
            etfEquitySectorExposureBarChartPromise,
            etfEquityIndustryExposureBarChartPromise,
            etfEquityCompanyExposureBarChartPromise,
            etfEquityHeadquartersExposureBarChartPromise,
            equitySectorIndustryExposureSunburstChartPromise,
            etfEquityHeadquartersExposureByEtfBarChartPromise,
            equityHeadquartersExposureMap(this.getEtfsByAssetClass(clientHoldingsFullyEnriched.reportDate.clientPositions, InstrumentType.Equity)),
            etfEquitySectorExposureBarChartCommentaryPromise,
            etfEquityIndustryExposureBarChartCommentaryPromise,
            etfEquityCompanyExposureBarChartCommentaryPromise,
            etfEquityHeadquartersExposureBarChartCommentaryPromise,
            etfEquityHeadquartersExposureByEtfBarChartCommentaryPromise,
            equitySectorIndustryExposureSunburstChartCommentaryPromise,
            fixedIncomeRatingsChart,
            fixedIncomeRatingsChartCommentary,
            etfOveralHeatmapPromise,
            etfOverallHeatapWithCommentaryPromise
        ])
            .then(([
                clientHoldingsSunburstPlot,
                etfEquitySectorExposureBarChart,
                etfEquityIndustryExposureBarChart,
                etfEquityCompanyExposureBarChart,
                etfEquityHeadquartersExposureBarChart,
                equitySectorIndustryExposureSunburstChart,
                etfEquityHeadquartersExposureByEtfBarChart,
                etfEquityHeadquartersExposureByPortfolioMap,
                etfEquitySectorExposureBarChartCommentary,
                etfEquityIndustryExposureBarChartCommentary,
                etfEquityCompanyExposureBarChartCommentary,
                etfEquityHeadquartersExposureBarChartCommentary,
                etfEquityHeadquartersExposureByEtfBarChartCommentary,
                equitySectorIndustryExposureSunburstChartCommentary,
                etfFixedIncomeRatingsBarChart,
                etfFixedIncomeRatingsBarChartCommentary,
                etfOverlapChart,
                etfOverlapChartWithCommentary
            ]) => {
                    return {
                        clientHoldingsSunburstPlot: {
                            image: clientHoldingsSunburstPlot
                        },
                        etfEquitySectorExposureBarChart: {
                            image: etfEquitySectorExposureBarChart,
                            commentary: etfEquitySectorExposureBarChartCommentary
                        },
                        etfEquityIndustryExposureBarChart: {
                            image: etfEquityIndustryExposureBarChart,
                            commentary: etfEquityIndustryExposureBarChartCommentary
                        },
                        etfEquityCompanyExposureBarChart: {
                            image: etfEquityCompanyExposureBarChart,
                            commentary: etfEquityCompanyExposureBarChartCommentary
                        },
                        etfEquityHeadquartersExposureBarChart: {
                            image: etfEquityHeadquartersExposureBarChart,
                            commentary: etfEquityHeadquartersExposureBarChartCommentary
                        },
                        etfEquitySectorIndustryExposureSunburstChart: {
                            image: equitySectorIndustryExposureSunburstChart,
                            commentary: equitySectorIndustryExposureSunburstChartCommentary
                        },
                        etfEquityHeadquartersExposureByEtfBarChart: {
                            image: etfEquityHeadquartersExposureByEtfBarChart,
                            commentary: etfEquityHeadquartersExposureByEtfBarChartCommentary
                        },
                        etfEquityHeadquartersExposureByPortfolioMap: {
                            image: etfEquityHeadquartersExposureByPortfolioMap
                        },
                        etfFixedIncomeCreditRatingExposureBarChart: {
                            image: etfFixedIncomeRatingsBarChart,
                            commentary: etfFixedIncomeRatingsBarChartCommentary
                        },
                        etfOverlapChart: {
                            image: etfOverlapChart,
                            commentary: etfOverlapChartWithCommentary
                        }
                    }
            })
    }

    getPortfolioAnalysisReportTables(clientHoldingsFullyEnriched: PortfolioAnalysisReportDataCollection): Promise<PortfolioAnalysisTables>{

        const top10UnderlyingEquitiesPromise = (clientHoldings: FullyEnrichedClientPosition[]): Promise<DataTableData> => new Promise(resolve => {
            resolve({
                columns: [
                    {
                        label: "Holding",
                        field: "holding",
                        width: 90
                    },
                    {
                        label: "Weight in Portfolio",
                        field: "weight",
                        width: 20
                    },
                    {
                        label: "GICS Sector Name",
                        field: "sector",
                        width: 60
                    },
                    {
                        label: "Company Market Cap (USD)",
                        field: "marketCap",
                        width: 40

                    },
                    {
                        label: "Foreast Dividend Yield (%)",
                        field: "dividendYield",
                        width: 30,
                    },
                    {
                        label: "Ccy",
                        field: "currency",
                        width: 10
                    },
                    {
                        label: "4-week Price Change (%)",
                        field: "priceChange",
                        width: 25,
                    },
                    {
                        label: "Forward PE Ratio",
                        field: "peRatio",
                        width: 30,
                    }
                ],
                // getEtfsByAssetClass => filter fullyEnrichedClientPositions for equity instruments
                // getTopEtfHoldings => iterate through clientPositions and slice off the largest weight found.
                // It looks like this is the function that we are going to change, as this is not what the table now is.
                // We will now be multiplying holding weight with etf weight then returning the top 10.
                // Lets include some other fields that may be of interest though
                rows: this.getTopEtfHoldings(this.getEtfsByAssetClass(clientHoldings, InstrumentType.Equity), 10)
                    .map(holding => {

                        return {
                            ric: holding?.instrument?.name,
                            holding: holding.etfPosition.underlyingInstrumentDescription || holding.etfPosition.underlyingInstrumentName || this.NotSpecified,
                            weight: `${holding.relativeWeight != null ? holding.relativeWeight.toFixed(2) + '%' : ''}`,
                            sector: holding?.instrument?.getAttributeValue(Attributes.Instrument.Eikon.GICSSector, this.NotSpecified),
                            marketCap: holding?.instrument?.getAttributeValue(Attributes.Instrument.Eikon.CompanyMarketCap, this.NotSpecified),
                            dividendYield: holding?.instrument?.getAttributeValue(Attributes.Instrument.Eikon.ForecastDividendYield, this.NotSpecified),
                            currency: holding.etfPosition.underlyingInstrumentCurrency || this.NotSpecified,
                            priceChange: holding?.instrument?.getAttributeValue(Attributes.Instrument.Eikon.FourWeekPriceChange, this.NotSpecified),
                            peRatio: holding?.instrument?.getAttributeValue(Attributes.Instrument.Eikon.ForwardPERatio, this.NotSpecified)
                        };
                    })
                });

        });

        const clientPositionsPromise = (clientHoldings: FullyEnrichedClientPosition[]): Promise<DataTableData> => new Promise(resolve => {

            let clientPositions = {
                columns: [
                    {
                        label: "Ticker",
                        field: "Ticker",
                        width: 35
                    },
                    {
                        label: "Name",
                        field: "name",
                        width: 80
                    },
                    {
                        label: "Domicile",
                        field: "domicile",
                        width: 40
                    },
                    {
                        label: "Total Expense Ratio (%)",
                        field: "expenseRatio",
                        width: 30
                    },
                    {
                        label: "Type",
                        field: "type",
                        width: 40
                    },
                    {
                        label: "Number of shares",
                        field: "shares",
                        width: 30
                    },
                    {
                        label: "Price",
                        field: "price",
                        width: 20
                    },
                    {
                        label: "Market Value (USD)",
                        field: "marketValue",
                        width: 25
                    },
                    {
                        label: "Weight",
                        field: "weight",
                        width: 30
                    }
                ],
                rows: this.getEtfsExcludingAssetClass(clientHoldings, InstrumentType.Cash).map(holding => {
                    return {
                        "Ticker": holding.clientPosition.identifiers()["Ticker"] || this.NotSpecified,
                        "name": holding.clientPosition.securityName || this.NotSpecified,
                        "domicile": !!holding?.instrument ? holding!!.instrument!!.getAttributeValue(Attributes.Instrument.Refinitiv.InstrumentDomicile) : this.NotSpecified,
                        "expenseRatio": !!holding?.instrument ? holding!!.instrument!!.getAttributeValue(Attributes.Instrument.Refinitiv.TotalExpenseRatio) : this.NotSpecified,
                        "type": holding.clientPosition.assetClass || this.NotSpecified,
                        "shares": holding.clientPosition.quantity.toFixed(0),
                        "price": holding.clientPosition.marketPrice.toFixed(2),
                        "marketValue": prettify(holding.clientPosition.marketValueReportingCurrency.toFixed(2)),
                        "weight": formatWeight(holding.clientPosition.calculateWeight(clientHoldings.map(holding => holding.clientPosition)))
                    }
                }).concat([this.combineCash(clientHoldings)])
            };

            clientPositions.rows.sort((a, b) => {
                if (a.type > b.type) {
                    return 1
                }

                if (a.weight > b.weight) {
                    return 1
                } else return 0;
            });

            resolve(clientPositions);
        });

        const clientPositionsBriefPromise = (clientHoldings: FullyEnrichedClientPosition[]): Promise<DataTableData> => new Promise(resolve => {
            resolve({
                columns: [
                    {
                        label: "RIC",
                        field: "RIC",
                        width: 25
                    },
                    {
                        label: "ETF Name",
                        field: "name",
                        width: 100
                    },
                    {
                        label: "Weight",
                        field: "weight",
                        width: 15
                    }
                ],
                rows: this.getEtfsExcludingAssetClass(clientHoldings, InstrumentType.Cash)
                .map(holding => ({
                    "RIC": holding.clientPosition.identifiers()["RIC"],
                    "name": holding.clientPosition.securityName,
                    "weight": formatWeight(holding.clientPosition.calculateWeight(clientHoldings.map(holding => holding.clientPosition)))
                }))

            })
        });

        const clientPositionsFixedIncomePromise = (clientHoldings: FullyEnrichedClientPosition[]): Promise<DataTableData> => new Promise(resolve => {
            resolve({
                columns: [
                    {
                        label: "RIC",
                        field: "ric",
                        width: 40
                    },
                    {
                        label: "Portfolio Weight",
                        field: "portfolioWeight",
                        width: 40
                    },
                    {
                        label: "Number of Instruments",
                        field: "numberInstruments",
                        width: 40
                    },
                    {
                        label: "Number of Issuers by Name",
                        field: "numberIssuersByName",
                        width: 40
                    },
                    {
                        label: "Yield to Worst",
                        field: "yieldToWorst",
                        width: 40
                    },
                    {
                        label: "Yield to Maturity",
                        field: "yieldToMaturity",
                        width: 40
                    },
                    {
                        label: "Modified Duration",
                        field: "modifiedDuration",
                        width: 40
                    }
                ],
                rows: this.getEtfsByAssetClass(clientHoldings, InstrumentType.FixedIncome).map(holding => {
                    return {
                        "ric": holding?.clientPosition.securityRic || this.NotSpecified,
                        "portfolioWeight": formatWeight(holding.clientPosition.calculateWeight(clientHoldings.map(holding => holding.clientPosition))),
                        "numberInstruments": holding?.etfPositions.length || 0,
                        "numberIssuersByName": new Set(holding?.etfPositions.map(etfPosition => etfPosition.instrument.getAttributeValue(Attributes.Instrument.Eikon.FiIssuerName, "Unknown")).filter(issuer => !(issuer == "Unknown"))).size,
                        "yieldToWorst": prettify(holding?.etfPositions.reduce((prev, curr) => prev + ((curr?.etfPosition?.weight || 0) / 100 * parseFloat(curr.instrument.getAttributeValue(Attributes.Instrument.Eikon.YieldToWorst, '0'))), 0).toFixed(2)),
                        "yieldToMaturity": prettify(holding?.etfPositions.reduce((prev, curr) => prev + ((curr?.etfPosition?.weight || 0) / 100 * parseFloat(curr.instrument.getAttributeValue(Attributes.Instrument.Eikon.YieldToMaturity, '0'))), 0).toFixed(2)),
                        "modifiedDuration": prettify(holding?.etfPositions.reduce((prev, curr) => prev + ((curr?.etfPosition?.weight || 0) / 100 * parseFloat(curr.instrument.getAttributeValue(Attributes.Instrument.Eikon.ModifiedDuration, '0'))), 0).toFixed(2)),
                    }
                })
            });
        });

        const clientPositionsFixedIncomeBriefPromise = (clientHoldings: FullyEnrichedClientPosition[]): Promise<[string, string][]> => new Promise(resolve => {
            resolve(this.getEtfsByAssetClass(clientHoldings, InstrumentType.FixedIncome).map(holding => {
                return [holding?.clientPosition.securityRic || this.NotSpecified, holding?.clientPosition?.securityName || holding?.instrument?.description || this.NotSpecified]
            }));
        });

        return Promise.all([
            clientPositionsPromise(clientHoldingsFullyEnriched.latestDate.clientPositions),
            clientPositionsBriefPromise(clientHoldingsFullyEnriched.reportDate.clientPositions),
            top10UnderlyingEquitiesPromise(clientHoldingsFullyEnriched.reportDate.clientPositions),
            clientPositionsFixedIncomePromise(clientHoldingsFullyEnriched.reportDate.clientPositions),
            clientPositionsFixedIncomeBriefPromise(clientHoldingsFullyEnriched.reportDate.clientPositions)
        ]).then(([
            clientPositions,
            clientPositionsBrief,
            top10UnderlyingEquities,
            clientPositionsFixedIncome,
            clientPositionsFixedIncomeBrief
        ]) => {
            return {
                clientPositions: clientPositions,
                clientPositionsBrief: clientPositionsBrief,
                top10UnderlyingEquities: top10UnderlyingEquities,
                clientPositionsFixedIncome: clientPositionsFixedIncome,
                clientPositionsFixedIncomeBrief: clientPositionsFixedIncomeBrief
            }})
    }
    
    getPortfolioAnalysisReportData(
        reportDate: string,
        latestDate: string,
        clientAccountNumber: string[]): Promise<PortfolioAnalysisReportDataCollection>{

        const getClientPositionsReportDatePromise = this.clientPositionsViewModel.getClientPositions(
            reportDate,
            clientAccountNumber
        );

        const getClientPositionsLatestDatePromise = this.clientPositionsViewModel.getClientPositions(
            latestDate,
            clientAccountNumber
        );

        // Get the holdings of the ETFs held by the client
        const getEtfDataReportDatePromise = getClientPositionsReportDatePromise.then(clientPositions => {
            return this.etfPositionsViewModel.getEtfPositions(
                reportDate,
                "ISIN",
                clientPositions
                    .filter(clientPosition => clientPosition.assetClass !== InstrumentType.Cash) // Don't want to resolve ETF positions for cash
                    .map(clientPosition => clientPosition.securityIsin)
            )
        });

        // Get overlap
        const getOverlapReportDatePromise = getEtfDataReportDatePromise.then(etfPositions => {
            return this.etfPositionsViewModel.getOverlap(
                reportDate,
                "KeyRIC",
                Array.from(
                    new Set(
                        etfPositions
                        .map(etfPosition => etfPosition.etfIdentifiers?.KeyRIC || "Unknown")
                        .filter(etfIds => !(etfIds == "Unknown"))
                    )
            ))
        });

        // Get the details for the instruments of both the ETFs themselves and their holdings
        const getInstrumentDataReportDatePromise = getEtfDataReportDatePromise.then(etfPositions => {
            let instrumentIds: Set<string> = new Set;

            etfPositions.forEach(etfPosition => {
                if (etfPosition.etfId != null){
                    instrumentIds.add(etfPosition.etfId)
                }

                if (etfPosition.underlyingInstrumentId != null){
                    instrumentIds.add(etfPosition.underlyingInstrumentId)
                }
            });

            return this.instrumentViewModel.getInstruments(
                Array.from(new Set(instrumentIds)),
                this.instrumentAttributes,
                reportDate
            ).then(instruments => {
                return Object.assign({}, ...instruments.map(
                    instrument => ({[instrument.ombaInstrumentId || ""]: instrument})
                ));
            })
        });

        // Once all the data has been fetched go ahead and combine it
        return Promise.all([
            getClientPositionsReportDatePromise,
            getEtfDataReportDatePromise,
            getInstrumentDataReportDatePromise,
            getOverlapReportDatePromise,
            getClientPositionsLatestDatePromise
        ])
        .then(([
            clientPositionsReportDate,
            etfDataReportDate,
            instrumentsReportDate,
            overlapReportDate,
            clientPositionsLatestDate
        ]) => {
            return {
                reportDate: {
                    clientPositions: this.combineData(clientPositionsReportDate, etfDataReportDate, instrumentsReportDate, "ReportDate"),
                    overlap: overlapReportDate
                },
                latestDate: {
                    clientPositions: this.combineData(clientPositionsLatestDate, etfDataReportDate, instrumentsReportDate, "LatestDate"),
                    overlap: overlapReportDate
                }
            }
        }
    )}
}

export default PortfolioAnalysisReportDataViewModel;
