import Instrument from "../instrument";
import InstrumentType from "../../../interfaces/enums/instrumentType";
import Identifier from "../identifier";
import OmbaIdentifier from "../../../interfaces/ombaApi/ombaIdentifier";
import InstrumentStore from "./instrumentStore";
import OmbaInstrument from "../../../interfaces/ombaApi/ombaInstrument";
import OmbaApiError from "../../../interfaces/ombaApi/ombaApiError";
import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";
import {ombaApiScopes} from "../../../authConfig";
import OmbaInstrumentStore from "./ombaInstrumentStore";
import DriveReferenceDataStore from "../../referenceData/stores/driveReferenceDataStore";
import InstrumentReferenceData from "../../referenceData/instrumentReferenceData";


class OmbaInstrumentStoreWithDriveEnrichment extends OmbaInstrumentStore {

    driveReferenceDataStore: DriveReferenceDataStore;

    constructor(
        baseApiUrl: string,
        driveReferenceDataStore: DriveReferenceDataStore,
        account?: AccountInfo,
        instance?: IPublicClientApplication,
        accessToken?: string){
            super(baseApiUrl, account, instance, accessToken);
            this.driveReferenceDataStore = driveReferenceDataStore;
    }

    getInstrument(instrumentId: string, attributes: string[]): Promise<Instrument> {

        return super.getInstrument(instrumentId, attributes);
    }

    getInstruments(instrumentIds: string[], attributes: string[]): Promise<Instrument[]> {
        return super.getInstruments(instrumentIds, attributes).then(instruments => {
            return this.driveReferenceDataStore.getInstrumentReferenceData([], null).then((instrumentReferenceData) => {
                return instruments.map(instrument => {
                    return this.enrichInstrument(instrument, instrumentReferenceData)
                })
            })
        })
    }

    private enrichInstrument(instrument: Instrument, instrumentReferenceData: InstrumentReferenceData): Instrument {
        if (!((instrument?.identifiers?.KeyRIC || "Unknown") in instrumentReferenceData.referenceData)){
            return instrument;
        }

        const referenceData = instrumentReferenceData.referenceData[instrument?.identifiers?.KeyRIC];

        if (instrument.attributes == null){
            instrument.attributes = {}
        }

        if (instrument.attributes["Eikon"] == null) {
            instrument.attributes["Eikon"] = {}
        }

        Object.entries(referenceData).forEach(([key, value]) => {

            if (instrument.attributes == null) {
                instrument.attributes = {}
            }

            let instrumentAttributeSource: string = 'Eikon';
            let instrumentAttributeType: string = key;
            instrument.attributes[instrumentAttributeSource][instrumentAttributeType] = value;

        })
        return instrument;
    }

}

export default OmbaInstrumentStoreWithDriveEnrichment