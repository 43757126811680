class PortfolioSummary {
    beginningMarketValue: number;
    depositsContributions: number;
    withdrawals: number;
    changeInValue: number;
    endingBalance: number;

    constructor(
        beginningMarketValue: number,
        depositsContributions: number,
        withdrawals: number,
        changeInValue: number,
        endingBalance: number
    ) {
        this.beginningMarketValue = beginningMarketValue;
        this.depositsContributions = depositsContributions;
        this.withdrawals = withdrawals;
        this.changeInValue = changeInValue;
        this.endingBalance = endingBalance;
    }
}

export default PortfolioSummary;
