import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";
import {ombaApiScopes} from "../../../authConfig";
import TransactionStore from "./transactionStore";
import LusidTransaction from "../../../interfaces/lusid/lusidTransaction";
import Transaction from "../transaction";

class LusidTransactionStore implements TransactionStore {

    baseApiUrl: string;

    account?: AccountInfo;

    instance?: IPublicClientApplication;

    private accessToken?: string;

    constructor(baseApiUrl: string, account?: AccountInfo, instance?: IPublicClientApplication, accessToken?: string, useLUSID?: boolean){
        this.baseApiUrl = baseApiUrl;
        this.account = account;
        this.instance = instance;
        this.accessToken = accessToken;
    }

    setAccessToken(accessToken: string): void {
        this.accessToken = accessToken;
    }

    getAccessToken(): Promise<string>{

        if (this.accessToken != undefined){
            return new Promise((resolve, reject) => resolve(this.accessToken!))
        };

        return this.instance!.acquireTokenSilent({
            ...ombaApiScopes,
            account: this.account
        }).then((response) => {
            return response.accessToken;
        });
    }

    getTransactions(clientCode: string, startDate: string, reportDate: string): Promise<Transaction[]> {
        return this.getAccessToken().then((accessToken) => {
            return fetch(`${this.baseApiUrl}/transactions/?clientCode=${clientCode}&reportStartDate=${startDate}&reportEndDate=${reportDate}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
            })
            .then(res => {
                if (!res.ok) {
                    throw new Error(`Failed to fetch performance data`);
                }
                return res.json();
            })
            .then((lusidTransactions: LusidTransaction[]) => {
                return lusidTransactions.map(transaction => {
                    return new Transaction(
                        transaction.BankAccountNumber,
                        transaction.PortfolioCode,
                        transaction.Description,
                        transaction.Currency,
                        transaction.TransactionDate,
                        transaction.SettlementDate,
                        transaction.Omba_TransactionType,
                        transaction.Type,
                        transaction.Units,
                        transaction.Price,
                        transaction.SettlementAmount,
                        transaction.Isin,
                        transaction.DisplayName,
                        transaction.ExchangeRate,
                        transaction.SettlementAmountBase
                    )
                })
            })
            .catch(error => {
                throw new Error("Transactions: " + error);
            });
        });
    }
}

export default LusidTransactionStore;
