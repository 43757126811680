import React from "react";
import SideBarInfo from "../interfaces/props/views/sideBarInfo";

{/* The Sidebar from the AdminLTE theme */}
function Sidebar(props:SideBarInfo) {

    const {
        person,
    } = props;

    return (
        <div>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                {/* Brand Logo */}
                <a href="index3.html" className="brand-link">
                    <img src="dist/img/omba-logo-circle.png" alt="Omba Logo"
                         className="brand-image img-circle elevation-3" style={{opacity: '.8'}}/>
                    <span className="brand-text font-weight-light">Omba Operations</span>
                </a>
                {/* Sidebar */}
                <div className="sidebar">
                    {/* Sidebar user panel (optional) */}
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div className="info">
                            <a href="login" className="d-block">{person.name}</a>
                        </div>
                    </div>
                    {/* SidebarSearch Form */}
                    <div className="form-inline">
                        <div className="input-group" data-widget="sidebar-search">
                            <input className="form-control form-control-sidebar" type="search" placeholder="Search"
                                   aria-label="Search"/>
                            <div className="input-group-append">
                                <button className="btn btn-sidebar">
                                    <i className="fas fa-search fa-fw"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* Sidebar Menu */}
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">
                            <li className="nav-item menu-open">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon fas fa-tachometer-alt"/>
                                    <p>
                                        Dashboards
                                        <i className="right fas fa-angle-left"/>
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a href="./instruments" className="nav-link">
                                            <i className="far fa-circle nav-icon"/>
                                            <p>Instruments</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="./etf_positions" className="nav-link">
                                            <i className="far fa-circle nav-icon"/>
                                            <p>ETF Positions</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="./client_positions" className="nav-link">
                                            <i className="far fa-circle nav-icon"/>
                                            <p>Client Positions</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                    {/* /.sidebar-menu */}
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">
                            <li className="nav-item menu-open">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon fas fa-tachometer-alt"/>
                                    <p>
                                        Reporting
                                        <i className="right fas fa-angle-left"/>
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a href="./reports" className="nav-link">
                                            <i className="far fa-circle nav-icon"/>
                                            <p>Portfolio Analysis</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="./clientreports" className="nav-link">
                                            <i className="far fa-circle nav-icon"/>
                                            <p>Client Reports</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
                {/* /.sidebar */}
            </aside>
        </div>
    );
}

export default Sidebar;