class PortfolioDetails {
    ombaBankAccountNumber: string;
    baseCurrency: string;
    accountingMethod: string;
    ombaCustodian: string;
    clientName: string;
    address: string;

    constructor(
        ombaBankAccountNumber: string,
        baseCurrency: string,
        accountingMethod: string,
        ombaCustodian: string,
        clientName: string,
        address: string
    ) {
        this.ombaBankAccountNumber = ombaBankAccountNumber;
        this.baseCurrency = baseCurrency;
        this.accountingMethod = accountingMethod;
        this.ombaCustodian = ombaCustodian;
        this.clientName = clientName;
        this.address = address;
    }
}

export default PortfolioDetails;
